.top_button_section {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 20px; */
}

.top_button_section ul {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 5px;
}

.top_button_section ul li:first-child {
  /* border-radius: 5px 0 0 5px; */
}

.top_button_section ul li:last-child {
  /* border-radius: 0 5px 5px 0;
  border-left: 0; */
}

.top_button_section ul li {
  border-radius: 16px;
  list-style: none;
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 60px;
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--gray500);
  /* border: thin solid var(--blue); */
  cursor: pointer;
}

.top_button_section ul li.active {
  background-color: var(--gray100);
  color: var(--gray800);
}

.pricing_item {
  border-radius: 15px !important;
  height: 100% !important;
  border: thin solid var(--gray300);
  padding: 10px 30px;
  position: relative;
}
.pricing_item.pro.billing {
  background-color: var(--yellow5050);
  border: 2px solid var(--yellow600);
}
.pricing_item.pro.billing .select_plan {
  background-color: var(--yellow600);
}
.pricing_item.most {
  border: 3px solid var(--yellow);
}

.pricing_item.most .select_plan {
  background-color: var(--yellow) !important;
}

.most_overlay {
  position: absolute;
  top: -3%;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  display: flex;
  justify-content: space-around;
  background-color: var(--yellow);
  align-items: center;
  padding: 10px;
  border-radius: 10px;
}

.most_overlay h4,
.most_overlay svg {
  color: #fff;
  font-weight: 600;
  margin-bottom: 0;
}

.pricing_item .pricing_item_header {
  width: 100%;
  margin-top: 20px;
  height: 65px;
}

.pricing_item .pricing_item_header h4 {
  color: var(--gray800);
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0 !important;
  display: inline;
  text-align: left;
}

.downgrad_plan_btn {
  background-color: var(--lightsky);
  color: var(--gray800);
  font-weight: 700 !important;
  font-size: 1rem;
  text-transform: uppercase;
  margin: 5px 0;
  outline: none;
  border: none;
  padding: 15px 30px;
  border-radius: 100px;
}

/* .pricing_item_price {
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */

/* .downgrad_plan_btn {
  background-color: var(--lightsky);
  color: var(--blue);
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  margin: 5px 0;
  outline: none;
  border: none;
  padding: 15px 30px;
  border-radius: 100px;
} */

.pricing_item .pricing_item_price {
  padding: 0 0px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: column;
}

.pricing_item .pricing_item_price .pricing {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;

}

.pricing_item .pricing_item_price .pricing sup {
  font-size: 1.2rem;
  margin-right: 5px;
  margin-top: 10px !important;
  font-weight: 600;
  color: var(--blue);
}

.pricing_item .pricing_item_price .pricing .dollar {
  font-size: 2.4rem;
  font-weight: 600;
  color: var(--gray800);
}

.pricing_item .pricing_item_price .pricing .amount {
  line-height: 1;
  font-size: 2.4rem;
  font-weight: 600;
  color: var(--gray800); 

}

.pricing_item .pricing_item_price .pricing .type {
  font-size: 1.2rem;
  margin-top: 15px;
  font-weight: 300;
  color: var(--gray500);
}

.select_plan, body .downgrad_plan_btn {
  color: #ffff;
  font-weight: 600 !important;
  font-size: 20px;
  width: 100%;
  text-transform: capitalize;
  margin: 10px 0;
  outline: none;
  border: none;
  padding: 15px 30px;
  border-radius: 100px;
  transition: all 0.5s ease-out;
  background-color: var(--blue6);
}

.disabled_select_plan {
  color: gray;
  font-weight: 500;
  font-size: 20px;
  width: 100%;
  /* text-transform: uppercase; */
  margin: 10px 0;
  outline: none;
  border: none;
  padding: 15px 15px;
  border-radius: 100px;
  transition: all 0.5s ease-out;
  background-color: #eee;
}

.current-badge {
  float: right;
  color: var(--gray500);
  padding: 4px 20px;
  background: var(--gray25);
  border-radius: 100px;
  font-weight: 600;
}
.current-badge.most-popular {
  background-color: var(--yellow100);
  color: var(--yellow700);
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

.current-badge.most-popular::before {
  content: "";
  background-image: url('./../new_theme/img/light.png');
  width: 14px;
  height: 14px;
  position: absolute;
  left: 14px;
  top: 8px;
  background-repeat: no-repeat;
  background-size: contain;
}

.current-badge.most-popular::after {
  content: "";
  background-image: url('./../new_theme/img/light.png');
  width: 10px;
  height: 14px;
  position: absolute;
  right: 12px;
  top: 8px;
  background-repeat: no-repeat;
  background-size: contain;
}

.pricing_item .pricing_item_price .details {
  margin: 20px 0;
  width: 100%;
}

.pricing_item .pricing_item_price .details h4 {
  color: var(--gray700);
  font-weight: 500;
  font-size: 1.3rem;
}

.pricing_item .pricing_item_price .details ul {
  padding-left: 2px !important;
  list-style: none;
}

.pricing_item .pricing_item_price .details ul li {
  padding: 4px 0 !important;
  font-size: 1rem;
  color: var(--gray500);
  font-weight: 400;
  position: relative;
  padding-left: 20px !important;
}


.pricing_item .pricing_item_price .details ul li:before {
  content: ".";
  position: absolute;
  left: 0;
  font-size: 38px;
  top: -6px;
}

.current_plan_item {
  /* border: 2px solid #e4ab3e;
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px; */
}

.strip_card {
  padding: 30px 20px;
  margin-bottom: 10px;
  background: rgba(187, 237, 255, 0.33);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border: 1px solid rgba(187, 237, 255, 0.61);
}
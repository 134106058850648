@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-Bold.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-BlackItalic.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-Black.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-BoldItalic.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-Medium.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-Italic.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-ExtraBoldItalic.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-SemiBoldItalic.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-LightItalic.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-Light.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-MediumItalic.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-SemiBold.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-Regular.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-ExtraBold.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-LightItalic.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./../fonts/webFonts/Figtree-Light.woff2') format('woff2'),
        url('./../fonts/webFonts/Figtree-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


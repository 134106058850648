.box-glow {
    width: 100%;
    padding: 25px;
    border: 1px solid #80808063;
    border-radius: 20px;
    min-height: 150px;
}

.fade-grey {
    background: #FCFCFD;
}
body .mini-btn-prim {
    background: var(--blue) !important;
    color: white;
    border-radius: 100px;
    padding: 6px 12px;
}
body .mini-btn-prim:hover {
    color:white !important;
    background-color: var(--gold) !important;
}
.close-icon {
    width: 16px;
    cursor: pointer;
}
body .billing-modal .modal-content {
    padding:16px; 
    background:white !important;
    overflow: auto;
}
body button.disabled {
    opacity: .7;
    cursor: not-allowed !important;
}
body button.deleteSubs {
    padding: 0;
}
body button.deleteSubs img {
    width: 17px !important;
    margin-right: 6px;
    position: relative;
    bottom: 2px;
}
.form-label {
    text-transform: initial !important;
}


body .billing-modal .loader {
    position: absolute;
    left: 0;
    top: 8px;
    z-index: 1;
    width: 20px;
    height: 20px;
    margin: auto;
    border: 3px solid #dc3545;
    border-radius: 50%;
    border-top: 4px solid white;
    animation: spin 1s linear infinite;
    transform: translate(-10px, -10px);
}
body .loader-side {
    position: relative;
    width: 30px;
    height: 30px;
    display: inline-block;
}

body .actions-modal button.bg-danger {
    border-radius: 100px;
    margin-left: 15px;
    background-color: #F04438 !important;
    padding: 5px 20px;
}
body .actions-modal button.text-black { 
    border-radius: 100px;
    margin-left: 15px;
    color:#002952 !important;
    background-color: #F9FAFB;
    padding: 5px 20px;
    font-weight: 600;
}

@media(max-width:767px){
    .close-icon {
        margin-bottom: 20px;
        float: right;
    }
    body .actions-modal button {
        width: 100%;
    }
    body .loader-side {
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
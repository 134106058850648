@charset "UTF-8";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css");
@import url('./variables.css');

.wd-100 {
  width: 100% !important;
}

.btn-primary {
  background-color: var(--darkblue);
  border: 0 !important;
  font-size: 0.9rem;
  border-radius: 0 !important;
  font-weight: 600;
}

.btn-default2 {
  background-color: var(--yellow);
  border: 0 !important;
  font-size: 0.9rem;
  border-radius: 0 !important;
  font-weight: 600;
  padding: 4px 20px
}

.btn-primary.radius,
.btn-default2.radius {
  border-radius: 10px !important;
}

.text-warning {
  color: var(--gold) !important;
  font-weight: 600 !important;
}

.btn-warning {
  font-size: 0.9rem !important;
  background-color: var(--yellow) !important;
  border: 0 !important;
  border-radius: 0 !important;
  color: #fff !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--darkblue);
  border: 0 !important;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: var(--gold);
}

b,
strong {
  font-weight: bold !important;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: var(--darkblue);
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  overflow-x: hidden;
  background-color: #f4f7fc;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
}

article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--defFont);
  color:var(--gray700);
  /* text-transform: uppercase; */
}

ul,
li {
  margin: 0;
  padding: 0;
}

a {
  font-family: var(--defFont);
  font-weight: normal;
  font-size: 16px;
  color: var(--darkblue);
  text-decoration: none;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

a:hover {
  text-decoration: none;
}

button:focus,
a:focus {
  outline: none;
  text-decoration: none;
}

img {
  border: 0 none;
}

p {
  margin: 0 0 15px 0;
}

/* .mapboxgl-ctrl-attrib-inner {
	display: none;
} */
.groupHeading {
  font-size: 16px !important;
  padding: 5px !important;
  background-color: #0b2548;
  color: #fff !important;
  text-align: center;
}

/*........side bar section........*/

.sidebar-main {
  position: sticky;
}

.side-nav ul li a {
  color: #a5a5a5;
}

.menu-title {
  color: #000000;
  text-align: left;
  font: normal normal bold 16px/25px Poppins;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 0;
  padding-top: 28px;
  padding-left: 28px;
}

.side-nav ul {
  list-style: none;
  padding: 20px 0;
}

.side-nav .notification {
  color: #fff;
  text-align: center;
  min-width: 25px;
  font-size: 12px;
  border-radius: 25px;
  display: inline-block;
  position: absolute;
  font-size: 10px;
  right: 5px;
  top: 15px;
  line-height: 18px;
  height: 17px;
  background: #f0142f 0% 0% no-repeat padding-box;
}

.side-nav ul li.active a {
  color: #011c40;
}

.top-right {
  max-width: 2500px;
  width: 100%;
}

#sidebar-wrapper.hideNav {
  /* position: fixed; */
  left: -180px;
  z-index: 2;
}

.hideNav .menu-tab {
  display: none !important;
}

.date-top .container {
  max-width: 500px;
  width: 100%;
}

.date-top .btn-group {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.date-top .btn {
  font-size: 11px;
  padding: 3px 10px;
  text-decoration: none;
  font-weight: 500;
}

.date-top .btn:focus {
  box-shadow: none;
}

.date-cont {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1px 7px 3px;
  line-height: 16px;
  color: #5a607f;
  font: normal normal normal 12px/21px Poppins;
  letter-spacing: 0.1px;
  line-height: 16px;
  max-width: 175px;
  font-family: "Poppins", sans-serif;
}

.date-cont span {
  padding-top: 3px;
  display: inline-block;
  position: relative;
  top: 1px;
}

.date-top .btn-group .active {
  background-color: #0058ff;
  color: #fff;
  border-radius: 5px;
  font-size: 11px;
  border-radius: 3px !important;
}

.date-cont {
  text-align: left;
}

.date-cont .icon-calender {
  display: inline-block;
  max-width: 20px;
  width: 100%;
  margin-right: 2px;
  font-family: "Poppins", sans-serif;
}

.side-nav ul li {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-nav ul li .icon {
  width: 30px;
  height: 30px;
  border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-nav ul li .text {
  padding-left: 20px;
}

.side-nav ul li .icon svg {
  font-size: 1.5rem;
}

.side-nav ul li:hover a {
  color: #011c40;
  text-decoration: none;
  position: relative;
}

.side-nav ul li:hover a:before {
  content: "";
  height: 30px;
  position: absolute;
  top: 2px;
  bottom: 0;
  right: -20px;
  width: 3px;
  background-color: #011c40;
}

.data-box {
  background: var(--grayFifty);
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
}

.data-box .sub-data-box {
  width: 100%;
  display: flex;
}

.data-box .data-div {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.data-box .icon-div {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-box .icon-div .data-icon {
  width: 50px;
  height: 50px;
  background-color: var(--darkblue);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.data-box .icon-div .data-icon svg {
  color: #fff;
  font-size: 1.6rem;
}

.data-box h6 {
  /* font-size: 1.2rem; */
  font-size: 1rem;
  font-weight: 600;
  color: var(--blue);
}



.data-box p span {
  color: #3dd598;
}

.data-box h3 {
  color: var(--blue);
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.12px;
  margin-top: 10px;
}

.bottom-box {
  display: inline-block;
  width: 100%;
  max-width: 95%;
  font-family: "Poppins", sans-serif;
}

.bottom-box .left-bottom {
  display: block;
  max-width: 312px;
  padding: 0 0px 0px 0;
  width: 100%;
  float: left;
  margin-right: 11px;
}

.bottom-box .right-bottom {
  display: block;
  max-width: 73%;
  padding: 0 0px 0px 25px;
  width: 100%;
  background-color: #fff;
  margin-left: 12px;
  float: left;
}

.data-box .icon {
  width: 45px;
  height: 45px;
  background: #0b2548;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  display: block;
  right: 12px;
  top: 28px;
  line-height: 41px;
}

.data-box .data {
  color: #7e84a3;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.data-box .data .up {
  text-align: left;
  letter-spacing: 0.1px;
  color: #3dd598;
  opacity: 1;
  padding-right: 5px;
  display: inline-block;
}

.chart-container {
  position: relative;
  overflow: hidden !important;
}

@media (min-width:1200px) {
  .chart-container canvas {
    height: 500px !important;
  }
}






.chart-container .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(239, 250, 254, 0.21);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.4px);
  -webkit-backdrop-filter: blur(5.4px);
  border: 1px solid rgba(239, 250, 254, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.chart-container .overlay p {
  text-align: center;
  width: 70%;
  margin: 0 auto;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 10px;
}

.chart-container .overlay p:nth-child(1) {
  font-weight: 600;
  color: var(--blue);
  font-size: 1.2rem;
}

@media screen and (max-width:380px) {
  .chart-container .overlay p {
    width: 90%;
    font-size: 0.9rem !important;
  }
}

.chart-container h3 {
  color: #131523;
  font-size: 18px;
  font-weight: 600;
}

.data-box .data .down {
  text-align: left;
  letter-spacing: 0.1px;
  color: #f0142f;
  opacity: 1;
  padding-right: 5px;
  display: inline-block;
}

.date-top {
  text-align: center;
  padding-top: 0px;
  padding-bottom: 40px;
  max-width: 2500px;
  width: 100%;
}

.login {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  background-color: #011c40;
  height: 100%;
  min-height: 100%;
}

.login-form {
  padding-top: 60px;
  padding-bottom: 10%;
  background: #011c40;
}

.login .login-logo {
  max-width: 300px;
  margin: 0 auto 30px;
}

.login-form .form h2 {
  font: normal normal bold 38px/57px Poppins;
  letter-spacing: 0px;
  color: #202020;
  font-size: 32px;
  margin: 0 0 0px 0;
  line-height: normal;
  font-family: "Poppins", sans-serif;
}

.login-form::placeholder {
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #202020;
}

.login-form .form label {
  font: normal normal bold 16px/25px Poppins;
  letter-spacing: 1px;
  color: #c7c7c7;
  font-size: 16px;
  margin: 0 0 5px 0;
  font-weight: 600;
}

.login-form .form p {
  font: normal normal 300 16px/25px Poppins;
  letter-spacing: 0px;
  color: #202020;
}

.login .form {
  background: #fff;
  border-radius: 13px;
  padding: 35px 25px 30px 40px;
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
}

.login .tab-container {
  padding-top: 15px;
}

.login .form legend {
  font-size: 1.5rem;
  min-height: 95px;
  margin: 0;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.login legend .fa {
  position: absolute;
  right: 10px;
  top: 10px;
}

.login legend .fa {
  position: absolute;
  right: 12px;
  top: 50px;
  font-size: 22px;
  cursor: pointer;
}

.login .form .submit {
  background: #011c40 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #00001f;
  width: 100%;
  color: #fff;
  text-align: center;
  font: normal normal bold 22px/27px Poppins;
  letter-spacing: 0px;
  height: 55px;
  border-radius: 10px;
  font-size: 18px;
  line-height: normal;
}

.login .reset-tab {
  height: 55px;
  background: #b4975a 0% 0% no-repeat padding-box;
  text-align: center;
  color: #fff;
  text-align: center;
  font: normal normal bold 22px/27px Poppins;
  width: 100%;
  display: block;
  border-radius: 10px;
  line-height: 56px;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.login::placeholder {
  color: #202020;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.forgot-password {
  padding: 20px 0;
  letter-spacing: 0px;
  text-decoration: none;
  color: #fff;
  font-size: 0.9rem;
  padding: 10px 0;
  margin: 10px 0;
}

.forgot-password:hover {
  color: #ffff;
}

.login .form input {
  width: 100%;
  height: 55px;
  border-radius: 10px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  padding-left: 15px;
  font-size: 18px;
  font-weight: 500;
  border: 0;
}

.login .error-messege {
  font-size: 14px;
  line-height: normal;
  color: #b4975a;
}

.login .login-logo img {
  width: 100%;
}

.nav-tab {
  width: 50px;
  height: 50px;
  border: 0;
  font-family: "Poppins", sans-serif;
}

.chart-container {
  width: 100%;
  background: #fff;
  padding: 20px;
  margin-bottom: 25px;
  min-height: 360px;
  overflow-x: scroll;
}

.chart-container::-webkit-scrollbar {
  display: none;
}

#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin 0.25s ease-out;
  position: relative;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  width: 100%;
  padding: 5px 25px;
  box-sizing: border-box;
  background-color: var(--bgTheme);
  min-height: 100vh;
}

.menu-tab {
  position: absolute;
  right: 10px;
  max-width: 32px;
  top: 6px;
  cursor: pointer;
}

.menu-tab:hover {
  transition: 0.2s;
  opacity: 0.5;
  cursor: pointer;
}

.menuTab-pageTitle h2 {
  color: #202020;
  font-weight: 600;
  font-size: 25px;
  margin-top: 13px;
  padding-top: 5px;
  font-family: "Poppins", sans-serif;
}

.menuTab-pageTitle .full-semi-menu {
  max-width: 40px;
  float: left;
  margin-right: 40px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.menuTab-pageTitle .full-semi-menu img {
  width: 100%;
  height: auto;
}

.menu-tab img {
  width: 100%;
}

.small-logo {
  display: none;
}

.small-logo {
  max-width: 50px;
  margin-left: 8px;
  margin-bottom: 10px;
}

.small-logo img {
  width: 100%;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
  font-family: "Poppins", sans-serif;
}

.header-notification {
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.header-notification ul {
  list-style: none;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}

.remain_downloads {
  background-color: var(--grayFifty);
  color: var(--darkblue);
  border-radius: 20px;
  font-size: 0.7rem;
  display: flex;
  padding: 10px 20px !important;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}

.remain_downloads span:nth-child(1) {
  margin-right: 10px;
}

.remain_downloads span svg {
  font-size: 1.1rem !important;
}
body .header-notification ul li.upgrade-skiptrace {
  padding: 11px 14px !important;
  position: relative;
  border: 1px solid var(--darkblue);
  background-color: white;
}

.remain_skiptrace {
  background-color: var(--grayFifty);
  border-radius: 20px;
  font-size: 0.7rem;
  display: flex;
  padding: 12px 15px !important;
  justify-content: center;
  align-items: center;
  width: 6rem;
  flex-direction: column;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.3s;
  border: 1px solid var(--darkblue);
}

.remain_skiptrace:hover,
.select_plan:hover {
  background-color: var(--gold);
  color:white;
}
body .header-notification ul li.upgrade-skiptrace:hover {
  background-color: var(--gold);
  color:white;
  border-color: var(--gold);
}

.remain_downloads span,
.remain_skiptrace {
  line-height: 1;
  color: var(--darkblue);
  font-weight: 600;    
  font-size: 14px;
}
.header-notification .bellIcon {
  width: 28px;
}
.storage-icon {
  width: 16px;
  margin-right: 10px;
}
.header-notification ul li {
  padding: 5px 10px;
  position: relative;
  margin-left: 15px !important;
}

.header-notification ul li svg {
  font-size: 1.5rem;
}

.header-notification ul .notification svg {
  font-size: 1.5rem;
  color: var(--text);
}

.header-notification ul .notification span {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #f01c1c;
  color: #fff;
  font-size: 0.5rem;
  font-weight: 500;
  top: 0;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
}

.header-notification .notification:hover {
  transition: 0.2s;
  cursor: pointer;
  opacity: 0.7;
}

/* Notification details list */

.header-notification .notify-dropdown {
  display: none;
  position: absolute;
  width: 400px;
  right: 10%;
  top: 70px;
  z-index: 2;
  /* max-height: 400px; */
  overflow-y: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: rgba(255, 255, 255, 0.87);
  border-radius: 10px;
  backdrop-filter: blur(11.8px);
  -webkit-backdrop-filter: blur(11.8px);
}

.header-notification .notify-dropdown::-webkit-scrollbar {
  display: none;
}

.header-notification .notify-dropdown ul {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.header-notification .notify-dropdown ul li {
  cursor: pointer;
  width: 100%;
  padding: 10px 20px;
  background-color: transparent;
  font-size: 1rem;
  transition: 0.5s;
}

.header-notification .notify-dropdown ul li:hover {
  transition: 0.2s;
  background: transparent linear-gradient(90deg, #011c4029 0%, #ffffff00 100%) 0% 0% no-repeat padding-box;
}

.header-notification .notify-dropdown .headofNotify {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: sticky;
  top: 0;
  background-color: #fff !important;
  z-index: 3;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.8px);
  -webkit-backdrop-filter: blur(11.8px);
}

.header-notification .notify-dropdown .headofNotify h1 {
  font-size: 1.4rem;
  margin-bottom: 0;
  font-weight: 600;
}

.header-notification .notify-dropdown .headofNotify a {
  cursor: pointer;
  font-size: 0.8rem;
  margin-top: 5px;
  color: #969696;
}

.header-notification .detail {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.header-notification .time {
  color: #aeaeae;
  font-size: 12px;
}

.header-notification .action {
  font-weight: 500;
  font-size: 0.7rem;
}

.header-notification .action:hover {
  font-weight: 400;
  transition: 0.2s;
}

.header-notification .message {
  font-size: 0.9rem;
  line-height: 18px;
  font-weight: 500;
  width: 90%;
}

.header-notification .avatar {
  display: flex;
  cursor: pointer;
  font-size: 0.8rem;
}

.header-notification .avatar .btn-primary {
  background-color: transparent !important;
  color: var(--darkblue);
  padding: 0 !important;
}

.header-notification .profileImg {

}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: none !important;
}

.header-notification .avatar img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.header-notification img.chevIcon {
  width: 20px;
  object-fit: contain;
  margin: 0px 10px;
  position: relative;
  top: -1px;
}

.header-notification .dropdown-menu {
  min-width: 12rem !important;
  border: 0 !important;
  padding: 0 !important;
  border-radius: 0 !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.header-notification .dropdown-menu .dropdown-item {
  color: var(--text) !important;
  font-weight: 400 !important;
}

.header-notification .dropdown-menu .dropdown-item svg {
  color: var(--darkblue);
  font-size: 1.2rem;
  margin: 5px;
}

.header-notification .dropdown-menu .dropdown-item:focus,
.header-notification .dropdown-menu .dropdown-item:hover {
  background: transparent linear-gradient(90deg, #011c4029 0%, #ffffff00 100%) 0% 0% no-repeat padding-box;
  color: var(--darkblue) !important;
}

.header-notification .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.header-notification .content img {
  width: 50px;
  height: 50px;
}

.search-container {
  position: relative;
  font-family: "Poppins", sans-serif;
}


.analytics-header {
  width: 100%;
  max-width: 100%;
  padding: 25px 30px 20px 55px;
  font-family: "Poppins", sans-serif;
  max-width: 2500px;
}

.analytics-header h2 {
  color: #171725;
  font-weight: 600;
  font-size: 24px;
  margin: 0 0 15px 0;
  font-family: "Poppins", sans-serif;
}

.search-container input {
  color: #7e84a3;
  font-size: 0.9rem;
}

.search-container input:focus-visible {
  box-shadow: none;
  border-bottom: 1px solid #ccc;
}

.loaderBlocker {
  background-color: rgba(0, 0, 0, 0.552);
  width: 100%;
  height: 100%;
  z-index: 901;
  position: fixed;
  top: 0;
  left: 0;
  cursor: wait;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  position: absolute;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  z-index: 1;
  width: 80px;
  height: 80px;
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  border-top: 12px solid var(--darkblue);
  animation: spin 1s linear infinite;
}

.loader-small {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--darkblue);
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.paginationActive {
  color: #333 !important;
}

.search-container input {
  color: #7e84a3;
  border: 1px solid #c2c2c2;
  background-color: transparent;
  border-radius: 5px;
  padding: 5px 10px;
  width: 99%;
}

.search-container input:focus-visible {
  outline: none;
}

.search-container button {
  position: absolute;
  top: 8px;
  left: 16px;
  border: 0;
  background-color: transparent;
  rotate: 90deg;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.main-title-desktop {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 10px 15px;
}

.main-title-mobile {
  /* font-size: 1.2rem; */
  font-size: 1.5rem;
  font-weight: 600;
  margin: 10px;
  display: none;
}


.upload-btn-list {
  border-radius: 100px !important;
  padding: 10px 20px;
  float: right;
}

.upload-btn-list img {
  width: 15px;
  margin-right: 8px;
}

@media (max-width: 1200px) {
  .main-title-mobile {
    display: block !important;
  }

  .main-title-desktop {
    display: none !important;
  }
}

/*........top section........*/
.main-header {
  padding: 15px 25px;
  position: relative;
}



.main-header .input-group {
  border-radius: 20px !important;
  border: 1px solid var(--text);
  overflow: hidden;
}

.main-header .input-group .input-group-text {
  border: 0 !important;
  color: var(--darkblue);
  background-color: transparent !important;
}

.main-header .input-group .input-group-text button {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}

.main-header .input-group .form-control {
  border: 0 !important;
}

.main-header .input-group .form-control::placeholder {
  font-size: 0.8rem;
}

.main-header .input-group .form-control:focus {
  background-color: transparent !important;
}

/*--------My Lists page -----------*/
.mylists-page {
  max-width: 2500px;
  width: 100%;
  /* padding: 10px; */
  min-height: 90vh;
  background-color: #fff;
}

.mylists-page .container {
  max-width: 100%;
  width: 100%;
}

.filestyle {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filestyle p {
  margin-left: 10px;
  margin-bottom: 0 !important;
}

.filestyle p span {
  font-weight: 600;
  font-size: 0.9rem;
  color: #2f9a00;
}

.filestyle input {
  opacity: 0;
  width: 190px;
  left: 0;
  top: 0;
  z-index: 1;
  height: 55px;
}

.file_btn_lable label {
  background-color: var(--darkblue) !important;
  border-radius: 10px;
  color: #fff;
  font-size: 0.9rem;
  margin-right: 20px;
  font-weight: 500;
  padding: 10px 35px;
}

.file_btn_lable input {
  opacity: 0;
}

.file_btn_lable label i {
  margin-right: 5px;
}

.fileupload-container .hitcredits {
  font-weight: 600;
  font-size: 14px;
  color: #000;
  padding-top: 20px;
}

.fileupload-container .hitcredits .resp-table2 table {
  min-width: 1200px;
}

.payment-skin-struct {}
.payment-skin-struct img.icon {
  max-width: 60px;
  width: 100%;
}
.payment-skin-struct .container-content {}
.addSkips-cst .container-content span {
  width: auto;
  height: auto;
  background: initial;
  margin: 0;
  padding: 0;
}
.form-check-cst {
  display: flex;
  flex-direction: row-reverse;
  padding: 0;
}
.form-check-cst .form-check-label {
  width: 100%;
}
.form-check-cst .form-check-input {
  margin: 0;
  padding: 0;
  position: relative;
  top: 10px; 
  width: 25px;
  height: 25px;    
  border: 1px solid var(--gray300) !important;
}
body .wd-12 {width: 12.666% !important;}
body .cst-wdth-skp {
  width: 11.966% !important;
  padding-right: 0;
}
.payment-skin-struct .container-content .name {
  color: var(--gray900);
  font-size: 20px;
}
.payment-skin-struct .container-content .expiry {
  color: var(--gray500);
  font-size: 16px;
}
.payment-skin-struct .container-content .badge {
  color: var(--gray500);
  font-weight: 500;
  background: var(--grayFifty);
  padding: 9px 20px;
  border-radius: 100px;
  font-size: 14px;
}
.payment-skin-struct .container-content .number {
  color: var(--gray500);
  font-size: 20px;
  margin-left: 10px;
}


.resp-table2 thead th {
  color: var(--gray900);
  font-weight: 600 !important;
  font-size: 0.9rem !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 !important;
}

thead,
tr th {
  font-weight: 600;
}

.resp-table2 thead tr {
  border: 0 !important;
  transition: 0.2s linear;
  background-color: transparent !important;
  border-bottom: 1px solid #eee !important;
}

.resp-table2 tbody tr {
  border: 0 !important;
  transition: 0.2s linear;
  background-color: transparent !important;
  border-bottom: 1px solid var(--gray200) !important;
  color: black;
}

.resp-table2 table tbody tr:hover {
  background-color: #eeeeeebe !important;
}


.resp-table2 td:nth-child(1),
.resp-table2 th:nth-child(1) {
  padding-left: 20px;
  /* text-align: center; */
}

.resp-table2 a i {
  color: #0b2547;
  font-size: 30px;
}

.resp-table2 .btn01 {
  border-radius: 25px;
  background-color: #0b2547;
  border: #0b2547 1px solid;
  border-radius: 100px !important;
  font-size: 1rem;
  padding: 0.2rem 0.5rem !important;
  color: #fff;
  margin-right: 5px;
}

.resp-table2 .btn02 {
  background-color: #d4af62;
  border: #d4af62 1px solid;
  color: #fff;
  border-radius: 100px !important;
  font-size: 1rem;
  padding: 0.2rem 0.5rem !important;
}

.resp-table2 .btn03 {
  background-color: #5f95b9;
  border: #d4af62 1px solid;

  color: #fff;
  border-radius: 100px !important;
  font-size: 1rem;
  padding: 0.2rem 0.5rem !important;
}

.resp-table2 .btn04 {
  background-color: #d25e5e;
  color: #fff;
  border-radius: 100px !important;
  font-size: 19px;
  padding: 1px 6px;
}

.resp-table2 .time {
  display: block;
}

.resp-table2 .done {
  display: block;
}

.resp-table2 table,
tbody {
  width: 100%;
}

ul.list-table-dropdown {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.list-table-dropdown li {}
ul.list-table-dropdown li button.show {
  margin: auto !important;
}
ul.list-table-dropdown li button {
  background: transparent;
  padding: 0;
  margin: 0;
}

ul.list-table-dropdown li button:hover,
ul.list-table-dropdown li button:focus {
  background-color: transparent !important;
}
ul.list-table-dropdown li button img {
  width: 25px;
}
ul.list-table-dropdown {}

ul.list-items-micro {}
.list-items-micro span {
  position: relative;
  top: 2px;
  color: var(--darkblue);
}

.list-items-micro img {
  width: 18px;
  margin: 0 10px 0 0;
}
.dropdown-menu.show {
  border-radius: 10px !important;
}



.resp-table2 table tbody tr td {
  font-size: 0.9rem;
  padding: .8rem .5rem;
  color: var(--gray900);
  font-weight: 400;
}


table.table-dim-skin td {
  color:var(--gray900) !important;
  vertical-align: middle;
  font-weight: 400;
}
table.table-dim-skin>:not(caption)>*>* {
  padding: 1rem .5rem;
}
table.table-dim-skin {}
.download-table.pl-all-4 {
  padding-left: 20px !important;
  padding-top: 10px !important;
}
button.download-table {
  background: transparent;
  color: var(--darkblue);
  font-weight: 500;
  border: 1px solid var(--gray300) !important;
  border-radius: 50px !important;
  cursor: pointer;
  padding: 5px 15px;
  display: flex;
}
body .btn-primary:hover
body .btn-primary:focus {
  background-color: var(--gray300);
  color: var(--gray900);
}
button.download-table img {
  margin-top: 3px !important;
  width: 15px;
  margin-right: 10px;
}
.hr-custom {
  background-color: var(--gray300) !important;
}
body h2.bill-gap {
  margin: 10px 0px !important;
  margin-bottom: 32px !important;
}

button.download-table:hover {
  transition: .2s;
  background-color: var(--gray300);
  color: var(--gray900);
}

.resp-table2 {
  padding: 10px;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid var(--gray200);
  padding-top: 6px;
  background-color: var(--gray25);
}

.cst-pagination ul li:hover {
  opacity: .7;
  cursor: pointer;
  transition: .2s;
}

.cst-pagination img.next-icon {
  width: 8px;
  position: relative;
  top: -1px;
  margin-left: 4px;
}
.cst-pagination img.prev-icon {
  width: 8px;
  position: relative;
  top: -1px;
  margin-right: 4px;
}
.cst-pagination {
  /* background: white;
  margin: -16px -11px -20px -10px;
  border-radius: 0px 0px 10px 10px;
  padding: 30px; */
  justify-content: end;
}
body .cst-pagination ul {
  left: initial;
  transform: initial;
}

/*--------My Lists page -----------*/

/*''''''''''''Property Search-----------*/
.mapbox-popup {
  min-width: 350px;
  margin-top: -30px;
}

.mapbox-popup .mapboxgl-popup-content {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  color: var(--blue);
  font-family: "Poppins", sans-serif !important;
}

.mapPopContent strong {
  font-size: 0.7rem;
  color: var(--blue);
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
}

.mapPopContent p {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.mapPopContent ul {
  padding-left: 10px;
}

.mapPopContent ul:nth-child(1) li {
  font-weight: 400;
  margin-bottom: 5px;
}

.detailList {
  display: flex;
  list-style: none;
  padding: 10px 10px 0 10px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.detailList li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.detailList li::after {
  content: '';
  width: 1px;
  height: 100%;
  right: -10px;
  position: absolute;
  background-color: #d8dce2;
}

.detailList li:last-child:after {
  content: none;
}

.detailList li p {
  font-size: 0.7rem !important;
}

.detailList li h6 {
  font-weight: 400;
  font-size: 0.8rem !important;
}

.mapboxgl-popup-close-button {
  right: 10px !important;
  top: 10px !important;
  font-size: 1.2rem !important;
}

.mapboxgl-popup-close-button:hover {
  color: var(--gold);
  background-color: transparent;
}

.marker_div {
  background-color: #3dca77;
  color: #fff;
  padding: 1px 10px;
  font-weight: 500;
  border-radius: 8px;
  position: relative;
  font-size: 13px;

  /* border: 1px solid lightgreen; */
}

.marker_div::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 9px solid #3dca77;
  border-bottom: 8px solid transparent;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
}

/* 



.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: var(--gold) !important;
} */

.record-filter {
  position: absolute;
  top: 40px;
  left: 25px;
  max-width: 1100px;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.property-Search {
  background-color: #fff;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.property-Search .container {
  max-width: 1600px;
  width: 100%;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.status-cont {
  padding-top: 13px;
}

.property-Search .search-cont {
  max-width: 500px;
}

.property-Search .search-cont {
  width: 100%;
  max-width: 300px;
  position: relative;
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
}

.record-filter .search-cont {
  width: 100%;
  max-width: 355px;
  position: relative;
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
}

.record-filter .filter-tabs {
  padding-top: 5px;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.search-cont input[type="search"] {
  width: 100%;
  border: 2px solid #0c2548;
  border-radius: 25px;
  height: 40px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #0b2548;
  font-size: 14px;
  margin-top: 4px;
  padding-left: 40px;
}

.record-filter .skiptrace {
  float: left;
  margin-right: 8px;
}

.record-filter .skiptrace {
  background: #0b2548;
  color: #fff;
  border-radius: 5px;
  display: inline-block;
  padding: 7px 10px 9px;
  border-radius: 50px;
  text-transform: capitalize;
  font: normal normal 400 16px/20px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

#quick-filter-results {
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  height: 700px;
  z-index: 2;
  padding: 15px 10px;
  max-width: 920px;
  font-family: "Poppins", sans-serif;
}

.main-alert-box {
  position: absolute;
  background-color: #011c40;
  max-width: 200px;
  top: 0px;
  padding: 10px;
  right: -100%;
  bottom: 0;
  width: 100%;
  color: #fff;
  right: -199px;
  line-height: 16px;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
}

.main-alert-box .p-data {
  list-style: none;
  display: inline-block;
}

.main-alert-box .p-data li {
  list-style: none;
  display: inline-block;
}

.main-alert-box .remove-f {
  cursor: pointer;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.main-alert-box div#accordionMain {
  padding-top: 10px;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.main-alert-box .alert-box-footer {
  position: absolute;
  bottom: 15px;
  max-width: 200px;
  text-align: center;
  width: 100%;
}

div#accordionMain {
  padding-top: 15px;
}

.main-alert-box .alert-box-footer .cancel-tab {
  width: 70px;
  height: 30px;
}

.main-alert-box .alert-box-footer .save-tab {
  width: 70px;
  height: 30px;
  background: #b4975a;
  color: #fff;
}

.main-alert-box h6 {
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 0;
  display: inline-block;
  font-family: "Poppins", sans-serif;
}

.filter-header {
  max-width: 875px;
  margin: 0 auto;
}

#quick-filter-results .close- {
  color: #000;
  font-size: 21px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

#quick-filter-results h3 {
  font-size: 18px;
  text-transform: uppercase;
  line-height: 20px;
  color: #454545;
  margin: 0 0 5px 0;
  font-family: "Poppins", sans-serif;
}

#quick-filter-results .search-f {
  max-width: 170px;
  width: 100%;
  float: left;
  margin-right: 15px;
  font-family: "Poppins", sans-serif;
}

#quick-filter-results .quick-filter-tab {
  width: 100%;
  max-width: 160px;
  position: relative;
  float: left;
  font-family: "Poppins", sans-serif;
}

.main-alert-box .status-cont {
  padding: 20px 10px 15px 14px;
  max-width: 920px;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

/*----------custom switch--------------*/
.customSwitch .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.customSwitch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.customSwitch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.customSwitch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.customSwitch input:checked+.slider {
  background-color: #496c9c;
}

.customSwitch {
  text-align: center;
}

.customSwitch input:focus+.slider {
  box-shadow: 0 0 1px #496c9c;
}

.customSwitch input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.customSwitch .slider.round {
  border-radius: 34px;
}

.customSwitch .slider.round:before {
  border-radius: 50%;
}

/*----------custom switch--------------*/

.select-field {
  width: 100%;
  height: 36px;
  border: none;
  padding-left: 15px;
  background: #efefef;
  text-transform: uppercase;
  border-radius: 30px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.date-field {
  width: 100%;
  height: 36px;
  border: none;
  padding-left: 20px;
  padding-right: 15px;
  background: #efefef;
  text-transform: uppercase;
  border-radius: 30px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.section-box .select-box {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
}

.section-box .select-box .select3 {
  width: 100%;
  height: 37px;
  border: none;
  padding-left: 15px;
  background: #efefef;
  text-transform: uppercase;
  border-radius: 30px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.section-box .field-box input[type="text"] {
  width: 100%;
  height: 33px;
  border: none;
  padding-left: 10px;
  background: #efefef;
  text-transform: uppercase;
  border-radius: 30px;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.section-box .field-box input[type="text"] {
  width: 100%;
  height: 30px;
  border: none;
  padding-left: 10px;
  background: #efefef;
  text-transform: uppercase;
  border-radius: 30px;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.c-selectbox button {
  width: 100%;
  height: 33px;
  border: none;
  padding-left: 10px;
  background: #efefef;
  text-transform: uppercase;
  border-radius: 30px;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.dropdown-check-list {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.dropdown-check-list .anchor {
  width: 100%;
  height: 33px;
  border: none;
  padding-left: 10px;
  background: #efefef;
  text-transform: uppercase;
  border-radius: 30px;
  font-size: 14px;
  padding-left: 15px;
  padding-top: 4px;
  font-weight: 500;
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.dropdown-check-list .anchor:after {
  content: "\f078";
  font-family: "fontAwesome";
  font-size: 18px;
  position: absolute;
  right: 10px;
  font-size: 12px;
  font-weight: bolder;
  color: #000;
  top: 4px;
}

.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list ul.items {
  padding: 0 10px;
  display: none;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  position: absolute;
  z-index: 4;
  background-color: #fff;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.dropdown-check-list ul.items li {
  list-style: none;
  border-bottom: 1px solid #ccc;
  padding: 2px 0;
  clear: both;
  color: #454545;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.dropdown-check-list ul.items li label {
  display: block;
  clear: both;
  font-family: "Poppins", sans-serif;
}

.dropdown-check-list ul.items li label input[type="checkbox"] {
  float: right;
  clear: both;
  position: relative;

  top: 5px;
  font-family: "Poppins", sans-serif;
}

.dropdown-check-list.visible .anchor {
  width: 100%;
  height: 33px;
  border: none;
  padding-left: 10px;
  background: #efefef;
  text-transform: uppercase;
  border-radius: 30px;
  font-size: 14px;
  padding-left: 15px;
  padding-top: 4px;
  font-weight: 500;
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.dropdown-check-list.visible .items {
  display: block;
}

.section-box .field-box label {
  font-size: 13px;
  color: #454545;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.section-box .field-box input:focus-visible {
  border: none;
  outline: none;
}

.field-box ::placeholder {
  color: #333;
  font-family: "Poppins", sans-serif;
}

#accordionMain .accordion-button:focus-visible {
  border: none;
  outline: none;
}

.status-cont label {
  font-weight: 500;
  margin-right: 10px;
}

#quick-filter-results .quick-filter-tab .quick-tab {
  background-color: #011c40;
  text-align: center;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  width: 100%;
  height: 36px;
  border-radius: 30px;
  position: relative;
  top: -1px;
  font-family: "Poppins", sans-serif;
}

#quick-filter-results .search-f select:focus-visible {
  border: none;
}

#quick-filter-results header {
  padding-top: 15px;
}

.record-filter .skiptrace a {
  color: #fff;
  font: normal normal 400 16px/20px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 13px;
  text-decoration: none;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: var(--darkblue);
  text-align: left;
  background-color: #f2f2f2;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  font-family: "Poppins", sans-serif;
}

.record-filter .submit {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 11px;
  left: 5px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.record-filter.search-cont input[type="search"] {
  width: 100%;
  border: 2px solid #0c2548;
  border-radius: 25px;
  height: 45px;
  font: normal normal bold 17px/20px Poppins;
  letter-spacing: 0px;
  color: #0b2548;
  padding-left: 40px;
  font-size: 15px;
}

.record-filter .search-cont::placeholder {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #0b2548;
  font-family: "Poppins", sans-serif;
}

#accordionMain .accordion-button {
  padding: 7px 10px 7px 30px;
  text-transform: uppercase;
  font-size: 15px;
}

#accordionMain .accordion-item {
  border: none;
  margin-bottom: 3px;
}

#accordionMain .accordion-button::after {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  margin-left: auto;
  content: "";
  background-image: none;
  background-repeat: no-repeat;
  background-size: auto;
  transition: transform 0.2s ease-in-out;
  font-family: "Poppins", sans-serif;
}

#accordionMain .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #496c9c;
  box-shadow: none;
}

#accordionMain .accordion-button::after {
  content: "\f067";
  font-family: "Font Awesome 6 Free";
  position: absolute;
  left: 9px;
  top: 8px;
  transform: rotate(0deg) !important;
}

#accordionMain .accordion-button:not(.collapsed)::after {
  content: "x";
  top: 9px;
  left: 12px;
  font-weight: bold;
  font-size: 12px;
}

#sidebar-wrapper {
  margin-left: 0;
}

.sidebar.active .scrollable-xs {
  overflow-y: auto;
  height: 100%;
}



/* Track */
.sidebar.active .scrollable-xs::-webkit-scrollbar-track {
  background: #f1f1f1d3;
}

/* Handle */
.sidebar.active .scrollable-xs::-webkit-scrollbar-thumb {
  background: #9b9b9bc7;
}

/* Handle on hover */
.sidebar.active .scrollable-xs::-webkit-scrollbar-thumb:hover {
  background: #7c838c;
}

#sidebarToggle {
  display: none;
  border: none;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: -15rem;
}

#root {
  height: 100%;
  min-height: 100%;
}

/*--------profile page -----------*/
.content-page {
  max-width: 2500px;
  width: 100%;
  padding: 30px 25px;
}

.content-page h4 {
  letter-spacing: 0px;
  color: #011c40;
  font-size: 1.1rem;
  font-weight: 600;
}

.content-page h4 a {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  text-decoration: underline;
}

.content-page .container {
  max-width: 100%;
  width: 100%;
  background-color: #fff;
  padding: 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 4px #0000000a;
  border-radius: 5px;
  overflow: hidden;
}

.content-page .form-check-input:checked {
  background-color: #b4975a;
  border-color: #b4975a;
}

.formComponent {
  margin: 10px 0;
}

.custom-field {
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  color: #202020;
}

.profile-head {
  color: #fff !important;
  background: linear-gradient(90deg, var(--darkblue), transparent);
  padding: 10px;
  margin: 10px 0;
}

.profile-head2 {
  color: var(--darkblue) !important;
  background: linear-gradient(270deg, var(--darkblue), transparent);
  padding: 10px;
  margin: 10px 0;
}

.formComponent label {
  letter-spacing: 1px;
  color: var(--text);
  font-size: 0.7rem;
  font-weight: 500;
  margin-bottom: 0;
}

.formComponent label i {
  color: var(--darkblue);
}

/* profile select option design */
.css-3d1h8d-control {
  border-radius: 5px !important;
  height: inherit !important;
}

.css-3d1h8d-control {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.css-pa51dx-control {
  height: inherit !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  font-weight: 500 !important;
  border-color: var(--darkblue) !important;
}

.form-control:focus {
  box-shadow: none !important;
  /* border-color: var(--darkblue) !important;
  background-color: var(--lightsky) !important; */
}

.css-19csfda-control {
  background-color: transparent !important;
}

.formComponent .PhoneInputInput {
  border-radius: 5px;
  color: #202020;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da !important;
}

.formComponent .PhoneInputInput:focus {
  border: 1px solid var(--darkblue) !important;
}

#uploadImage {
  display: none;
}


.uploadPreview {
  width: 140px;
  height: 140px;
  border-radius: 10px;
  /* background-color: var(--lightsky); */
  margin: 0 auto;
  /* border: 1px solid var(--blue); */
  padding: 5px;
  overflow: hidden;
}

.sidebar ul .logo img.bar-closed {
  position: fixed;
  left: 78px;
  z-index: 222222;
  top: 29px;
  width: 40px;
  height: auto;
}
.sidebar ul .logo img.bar-expanded {
  transform: rotate(180deg);
}

.main-header .main-title-desktop.ml-4 {
  /* padding-left: 35px; */
}

.current-plan-container {
  position: absolute;
  top: -20px;
  border: 1px solid #d9d9d9;
  border-radius: 100px;
  left: 50%;
  transform: translateX(-50%);
}

.uploadPreview img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.overlay_setting {
  width: auto;
  min-height: auto;
  border-radius: 50px;
  border: 1px solid var(--darkblue);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  margin: 0px auto;
  padding: 0px 5px;
  box-sizing: border-box;
  position: relative;
  top: calc(50% - 8px);
  transform: translateY(-50%);
}

.overlay_setting label {
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.overlay_setting i {
  color: var(--blue);
  height: 100%;
}

.overlay_setting span {
  color: var(--blue);
  font-weight: 600;
  margin-left: 10px;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.overlay_setting img.uploadIcon {
  width: 20px;
}

.settings_photo_remove {
  margin-top: 32px;
  margin-left: 15px;
  float:left;
}
/* .uploadPreview:hover .overlay {
  transform: scale(1);
}
.uploadPreview .overlay label {
  cursor: pointer;
  font-size: 2rem !important;
}
 */
/* .upload-button {
  box-shadow: 0px 4px 4px #0000000a;
  border: 1px solid #011c40;
  height: 50px;
  border-radius: 1px;
  text-transform: uppercase;
  color: #011c40;
  font-weight: 600;
  font-size: 16px;
  display: block;
  border-radius: 10px;
  text-align: center;
  padding-top: 12px;
} */
/* .upload-button i {
  position: absolute;
  left: 26px;
  top: 15px;
} */

.content-page .form-check label {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #202020;
  padding-top: 5px;
}

.content-page hr {
  margin: 0 0 39px;
  position: relative;
  margin-left: -47px;
  width: auto;
  margin-right: -47px;
}

/*--------profile page -----------*/

/*--------payment page -----------*/

.payment-container [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.payment-container [type="radio"]+img {
  cursor: pointer;
  border: 1px solid #a5a5a5;
  border-radius: 6px;
}

.payment-container [type="radio"]:checked+img {
  border: 2px solid #011c40;
  border-radius: 6px;
}

.payment-container header {
  text-align: center;
  margin-bottom: 60px;
}

.payment-container h2 {
  color: var(--gray700);
  font-size: 34px;
  /* font-weight: 700; */
  margin: 0 0 25px 0;
}

.payment-container {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.payment-container h4 {
  letter-spacing: 0px;
  color: #011c40;
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 24px 0;
}

.payment-container label {
  font-size: 10px;
  letter-spacing: 0px;
  color: #a5a5a5;
  text-transform: uppercase;
  font-weight: 600;
}

.payment-container {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 20px 10px;
  padding-top: 0;
}


.payment-container .btn-primary {
  border-radius: 100px !important;
  padding: 8px 20px;
  float: right;
}

/* .modal.show .modal-dialog {
  top: 25%;
} */

.payment-container .glassModel .modal-content .modal-body {
  padding: 20px 30px;
}

.payment-container input[type="text"],
.payment-container input[type="tel"],
.payment-container select {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  border: 1px solid #a5a5a5;
  border-radius: 4px;
}

.payment-container input[type="text"]:focus,
.payment-container input[type="tel"]:focus,
.payment-container select:focus {
  border: 1px solid #000;
  box-shadow: none;
}

/* steps */
.stripeForm label {
  font-size: 0.9rem;
  /* margin: 5px 0; */
  font-weight: 500;
}

.stripeForm {
  padding: 30px;
  background: transparent linear-gradient(180deg, #c4cedb29 0%, #ffffff00 100%) 0% 0% no-repeat padding-box;
  backdrop-filter: blur(7.4px);
  -webkit-backdrop-filter: blur(7.4px);
  border-top: 3px solid var(--darkblue);
}

.stripeForm .p-FieldLabel {
  font-weight: 500;
}

.stripeForm h2 {
  color: #011c40;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: 600;
}

.payment-container .submit-tab,
.stripeForm button {
  background: #011c40;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  width: 50%;
  display: block;
  margin: 10px auto;
}

.payment-container ::placeholder {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}

.adj-e {
  position: relative;
  padding-right: 48px;
  max-width: 285px;
}

.visa-icon {
  max-width: 72px;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

/*--------payment page -----------*/
.invoices-list-page .invoices-search {
  position: relative;
}

.inv-search-field {
  height: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 4px #0000000a;
  opacity: 1;
  width: 100%;
  border: 0;
  border-radius: 15px;
  padding-left: 15px;
  font-size: 16px;
  /* color: #; */
}

.invoices-list-page h3,
.subscriptionPlan h3 {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #202020;
  margin: 0 0 6px 0;
  font-weight: 700;
}

.invoices-list-page h6,
.subscriptionPlan h6 {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #a5a5a5;
  font-size: 18px;
  margin: 0 0 10px 0;
  font-weight: 400;
}

.inv-search-tab {
  width: 25px;
  height: 25px;
  opacity: 1;
  position: absolute;
  right: 22px;
  top: 19px;
  border: 0;
  font-size: 20px;
  background: none;
}

.invoices-list-page .list-pages {
  font-size: 100px;
  font-size: 16px;
  color: #000000;
  font-weight: 400;
}

.invoices-list-page .list-pages strong {
  font-weight: 700;
}

.invoices-list-page table,
.invoices-list-page th,
.invoices-list-page td {
  border-bottom: 2px solid #f4f4f4;
  padding: 15px 5px;
}

.invoices-list-page table,
tbody {
  font-weight: 300;
  width: 100%;
}

.resp-table {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.resp-table table tr td {
  font-size: 0.85rem;
}

.completed {
  background-color: #d8ffe0;
  color: #38e25d;
  border: 0;
  border-radius: 10px;
  width: 143px;
  height: 40px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  position: relative;
  padding-left: 40px;
}

.completed:hover {
  background-color: #d8ffe0;
  color: #38e25d;
  border: 0;
  border-radius: 10px;
  width: 143px;
  height: 40px;
}

.completed:focus {
  background-color: #d8ffe0;
  color: #38e25d;
  border: 0;
  border-radius: 10px;
  width: 143px;
  height: 40px;
  box-shadow: none;
}

.completed:focus:visible {
  background-color: #d8ffe0;
  color: #38e25d;
  border: 0;
  border-radius: 10px;
  width: 143px;
  height: 40px;
}

.completed i {
  font-weight: bold;
  font-size: 22px;
  position: absolute;
  left: 10px;
}

.pending {
  background-color: #ffebcc;
  color: #ffab2d;
  border: 0;
  border-radius: 10px;
  width: 143px;
  height: 40px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  position: relative;
}

.pending:focus {
  background-color: #ffebcc;
  color: #ffab2d;
  border: 0;
  border-radius: 10px;
  width: 143px;
  height: 40px;
  box-shadow: none;
}

.pending:focus:visible {
  background-color: #ffebcc;
  color: #ffab2d;
  border: 0;
  border-radius: 10px;
  width: 143px;
  height: 40px;
}

.pending:hover {
  background-color: #ffebcc;
  color: #ffab2d;
  border: 0;
  border-radius: 10px;
  width: 143px;
  height: 40px;
}

.pending:focus {
  background-color: #ffebcc;
  color: #ffab2d;
  border: 0;
  border-radius: 10px;
  width: 143px;
  height: 40px;
}

.pending i {
  font-weight: bold;
  font-size: 22px;
  position: absolute;
  left: 10px;
}

.bottom-footer-invoices .pagination a {
  border: 0;
  color: #202020;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  padding: 10px;
}

.page-item.active .page-link {
  background-color: transparent;
  color: #011c40 !important;
  font-weight: 700 !important;
}

.page-item .page-link {
  background-color: transparent;
  font-weight: 500 !important;
}

.bottom-footer-invoices .pagination a:hover {
  border: 0;
  color: #202020;
  text-decoration: none;
}

.bottom-footer-invoices .page-link:hover,
.bottom-footer-invoices .page-link:focus {
  z-index: 2;
  color: #333;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.middle-pager {
  display: inline-flex;
  border: 1px solid #c2c2c2;
  border-radius: 11px;
  padding: 2px 6px;
}

.first-pager {
  display: inline-flex;
  border: 1px solid #c2c2c2;
  border-radius: 11px;
  padding: 2px 6px;
  margin-right: 4px;
}

.last-pager {
  display: inline-flex;
  border: 1px solid #c2c2c2;
  border-radius: 11px;
  padding: 2px 6px;
  margin-left: 4px;
}

.first-pager a {
  font-size: 21px;
}

.invoices-list-page tr td:first-child {
  font-weight: 700;
  padding-left: 10px;
}

/*--------Invoices List page -----------*/

/* Property Search Starts */
.searchAddressInput::placeholder {
  font-weight: 400;
  color: var(--text);
}

.searchAddressInput {
  color: #011c40;
  padding: 10px 20px !important;
  font-weight: 5000;
  font-size: 1rem !important;
  /* background-color: var(--lightsky) !important; */
  border-radius: 5px !important;
}



/*........top section........*/
.main-header {
  background: var(--bgTheme);
  /* padding-left: 25px; */
}

.main-header .menu_toggle_icon {
  height: 100%;
  display: flex;
  align-items: center;
}

.main-header .menu_toggle_icon h2 {
  color: var(--gray800);
  font-size: 30px;
}

.main-header .menu_toggle_icon img {
  height: 25px;
  position: relative;
  cursor: pointer;
}

.main-header .menu_toggle_icon svg {
  cursor: pointer;
  font-size: 1.3em;
}

.main-header .container {
  width: 100%;
  max-width: 100%;
  padding: 15px 0;
}

.submitButton {
  background: #d5e7ee;
  color: #002854;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0px;
  height: 55px;
  border-radius: 10px;
  font-size: 16px;
  margin: 10px;
  padding: 0px 30px;
  text-transform: uppercase;
}

.resetButton {
  height: 55px;
  background: #f8f4ea;
  text-align: center;
  color: #002854;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  margin: 10px;
  padding: 0px 30px;
}


.cancelBtn:hover {
  background-color: var(--gold);
  border-color: var(--gold);
  cursor: pointer;
  transition:.2s;
}
.cancelBtn {
  background: #002854;
  /* width: 100% !important; */
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
}

.cancelBtn svg {
  font-size: 1.5rem;
  margin-right: 5px;
}

.saveBtn {
  background: #b4975a;
  color: #fff;
  width: 100% !important;
  font-weight: 500;
  border-radius: 10px;
  font-size: 0.9rem;
  border: 0;
  padding: 0.5rem;
}

.filterNav {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0 5px 100px;
}

.filterNav button {
  background: #0b2548;
  color: white;
  border-radius: 100px;
  padding: 6px 20px;
  font-size: 14px;
  margin-right: 5px;
  border: 0;
  margin-bottom: 10px;
}

.filterNav button:hover {
  transition: 0.2s;
  opacity: 0.8;
}

.filterNav button svg {
  margin-right: 10px;
}

/* filter area */
.mian_filter_class {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 1;
}

.filterArea .mian_filter_class .containerOfFilter {
  max-height: 500px;
  /* height: 400px; */
  width: 100%;
  /* overflow-x: hidden; */
  background-color: var(--gray200);
  border-radius: 10px;
  padding: 0 5px 5px 5px;
  margin-bottom: 20px;
  z-index: 0;
}

.filterArea .mian_filter_class .containerOfFilter::-webkit-scrollbar {
  display: block;
}

.filterArea .head-filter {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 5px 10px;
  /* background-color: #fff; */
}

.filterArea .head-filter h4 {
  font-size: 1rem;
  font-weight: 600;
}

.filterArea .dropdown {
  width: 100%;
}

.filterArea .dropdown button {
  background: #efefef;
  border-radius: 100px;
  padding: 5px 20px;
  width: 100%;
  font-size: 13px;
}

.filterArea .body-filter .top-area.active {
  /* background-color: #496c9c; */
  color: #fff;
}

.filterArea .body-filter .top-area {
  margin: 0;
  padding: 0;
  color: #000000;
  /* background-color: #efefef; */
  box-shadow: none;
}

.filterArea .body-filter .top-area .fav {
  width: 20px;
  display: inline-block;
}

.filterArea .body-filter .top-area span {
  line-height: 2;
  text-transform: uppercase;
  font-size: 18px;
  margin-left: 15px;
  cursor: pointer;
  margin-bottom: 2px;
}

.filterArea .body-filter .detailed-area label {
  font-weight: 500;
  font-size: 13px;
}

.searchBox {
  width: 100%;
}

body .multiSelectContainer input {
  box-shadow: none !important;
  /* width: 100%; */
}

body .multiSelectContainer input::placeholder {
  color: var(--blue) !important;
}

.filter_item label,
.label-check {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 5px 0;
  /* text-align: center; */
  display: block;
}

.filter_item .form-control::placeholder {
  color: var(--blue);
}

.filter_item .form-control {
  font-size: 0.9rem !important;
  border-radius: 5px !important;
  position: relative;
  /* padding: 0.175rem 0.75rem !important; */
  /* box-shadow: inset 0 0 10px #eee; */
  /* background-color: var(--lightsky); */
}

.form-check-label {
  font-size: 0.8rem;
}

.multiselect-container {
  border-radius: 5px !important;
  /* background-color: var(--lightsky); */
  color: var(--blue);
  padding: 0 0.75rem !important;
  /* box-shadow: inset 0 0 10px #eee; */
}

.searchWrapper input {
  font-size: 0.8rem;
  padding: 5px;
}

.filter_item .col-md-6 {
  position: relative;
  /* padding: 5px; */
}

.filter_item .col-md-6:nth-child(2):after {
  content: "_";
  position: absolute;
  top: -5px;
  left: -8px;
}

.searchWrapper {
  padding: 0 0.75rem !important;
  border: 0 !important;
}

.filterArea .btn-cstFilter {
  background: #efefef;
  border-radius: 100px;
  padding: 5px 20px;
  margin: 0px 0;
  width: 100%;
  font-size: 13px;
  color: black;
  border: 0;
}

.filterArea .btn-cstFilter.active {
  background-color: #0b2548 !important;
  color: white !important;
}

.filterArea .quickFilter-row {
  display: none;
}

.filterArea button svg {
  padding-left: 8px;
}

.rotateIcon {
  transform: rotate(180deg) translateX(-40%);
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.filter_item {
  border-radius: 10px;
  padding: 10px;
}

/* .filter_item .max {
  position: relative !important;
}
.filter_item .max::after {
  position: absolute;
  content: "";
  top: -5px;
  font-size: 20px;
  width: 5px;
  height: 10px;
  background-color: red;
  left: -8px;
} */

.chip,
.highlightOption,
.multiSelectContainer li:hover {
  background-color: #0b2548 !important;
}

.Dropdown-control {
  padding: 4px 32px 4px 15px;
  font-size: 14px;
  border-radius: 30px;
  cursor: pointer;
  background-color: #0b2548;
  color: white;
}

.Dropdown-menu {
  border-radius: 5px;
  font-size: 14px;
}

.Dropdown-option {
  padding: 2px 10px;
}

.accordion__button {
  background-color: var(--gray100);
  color: var(--blue);
  cursor: pointer;
  padding: 8px 20px;
  width: 100%;
  text-align: left;
  border: none;
  font-weight: 500;
}

.accordion {
  border-color: #c1e0eb !important;
}

.accordion__button:is(:focus, :active) {
  border: none !important;
}

.accordion__button:hover {
  background-color: var(--gray200) !important;
}

.fields-dropdown>.Dropdown-control {
  padding: 4px 32px 4px 15px;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  color: #0b2548;
  background-color: white;
}

.multiSelectContainer li {
  padding: 10px 10px;
  font-size: 12px;
}

/* The container */
.containerRadio {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
  margin-right: 20px;
}

/* Hide the browser's default radio button */
.containerRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.containerRadio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.containerRadio:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerRadio input:checked~.checkmark {
  background-color: #0b2548;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.containerRadio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio .checkmark:after {
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
}

.pd-0 {
  padding: 0 !important;
}

.br-0 {
  border-radius: 0 !important;
}

.slideContainer {
  width: 100%;
  /* height: 400px; */
  max-height: 500px;
  transition: 0.5s;
  margin-left: -100%;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 10px;
}

.slideContainer::-webkit-scrollbar {
  display: none;
}

.slideContainer.slideShow {
  margin-left: 0%;
}

.iconRemove_icon_div {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #eee; */
}

.iconRemove_icon_div_rotate {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* background-color: #eee; */
}

.iconRemove_icon_div_rotate .iconRemove_icon {
  transform: rotate(270deg);
  font-size: 0.8rem;
  transition: 0.5s;
  color: var(--darkblue) !important;
}

.iconRemove_icon_div .iconRemove_icon {
  transform: rotate(90deg);
  font-size: 0.8rem;
  transition: 0.5s;
  color: var(--darkblue) !important;
}

.slideBody {
  max-height: 80%;
}

.slideBody ul {
  padding: 10px 5px;
  list-style: none;
  width: 100% !important;
  height: 100%;
  overflow-y: scroll;
  margin-top: 20px;
}

.slideBody ul::-webkit-scrollbar {
  display: block;
}

.slideBody ul::-webkit-scrollbar-thumb {
  background: var(--blue) !important;
}

.slideBody ul li {
  background-color: #eee;
  color: #000 !important;
  font-size: 0.7rem;
  padding: 15px 10px 10px 10px;
  box-shadow: none;
  border-radius: 5px;
  margin: 5px 0;
  line-height: 15px;
  position: relative;
  overflow-x: scroll;
}

.slideBody ul li svg {
  margin-top: 5px;
}

.slideBody ul li span {
  display: inline-block;
  margin-left: 10px;
}

.slideBody ul li .labelData {
  color: var(--darkblue);
  font-size: 12px;
  font-weight: 600;
  float: left;
}

.slideBody ul li .taggedData {
  color: #000;
  font-size: 13px;
  margin-left: 5px;
  float: left;
}

.slideBody ul li .taggedData .tag-span {
  background-color: #d5e7ee;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 3px;
}

.slideBody ul li svg {
  top: -5px;
  right: 0;
  color: var(--text);
  position: absolute;
  cursor: pointer;
  border-radius: 10px;
  font-size: 1.2rem;
}

.slideFooter {
  text-align: center;
  margin: 10px 0;
  padding: 5px;
  position: sticky;
  bottom: 0;
  background: #fff;
}

.slideFooter button {
  border: 0;
  padding: 5px 20px;
  margin: 0 10px;
  border-radius: 100px;
  font-weight: 500;
  font-size: 0.9rem;
}

.slideFooter button.apply {
  color: #fff;
  background-color: var(--darkblue);
}

button.reset {
  background-color: var(--yellow) !important;
  color: #fff;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #485669;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7c838c;
}

.mapArea {
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: -9px;
}

.mapArea img {
  width: 100%;
}

.table-data {
  width: 100%;
  max-height: 600px;
  overflow: auto;
  display: block;
}

.table-data thead tr th {
  font-weight: 500;
  font-size: 14px;
}

.table-data tbody tr th {
  font-weight: 500;
  font-size: 14px;
  width: 20px;
}

.table-data tbody tr td {
  font-size: 13px;
}

.table-data .table {
  vertical-align: middle;
}

@media (max-width: 380px) {
  .pagination ul {
    justify-content: center;
  }
}

/* Pagenation desgin */
.pagination ul {
  list-style: none;
  max-width: 1000px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  left: 50%;
  transform: translateX(-50%);
}

.pagination ul li:hover {
  transition: 0.2s;
  opacity: 0.9;
  cursor: pointer;
}

.pagination ul li.active {
  background-color: #0b2548;
  color: white;
}


.pagination ul li a {
  border-radius: 70px; 
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 5px;
  width: 35px;
  height: 35px;
  color: var(--gray500) !important;
}

.pagination ul li:nth-child(7n),
.pagination ul li:nth-child(8n),
.pagination ul li:nth-child(9n),
.pagination ul li:nth-child(10n) {
  /* display: none; */
}
.pagination ul li.previous a,
.pagination ul li.next a {
  color: var(--gray900) !important;
  background-color: var(--grayFifty);
  font-weight: 500;
  width: 100px;
}
.pagination ul li.paginationActive a {
  color: var(--gray900) !important;
  background-color: var(--gray200);
  font-weight: 500;

}

/* The container */
.containerCheckbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
  left: 5px;
  top: -5px;
}

/* Hide the browser's default checkbox */
.containerCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.containerCheckbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #d1d1d1;
}

/* On mouse-over, add a grey background color */
.containerCheckbox:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheckbox input:checked~.checkmark {
  background-color: #0b2548;
  border: 0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.containerCheckbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerCheckbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerCheckbox .checkmark:after {
  left: 8px;
  top: 2px;
  width: 6px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

button.downloadRecords {
  background: #0b2548;
  color: white;
  border-radius: 100px;
  padding: 7px 20px;
  margin: 0 10px;
  font-size: 14px;
  border: 0px;
}

a.downloadRecords {
  background: #0b2548;
  color: white;
  border-radius: 100px;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 14px;
  text-decoration: none;
}

/* Property Search Ends */

/*--------Invoices List page -----------*/

@media (min-width: 1201px) {
  .payment-container header {
    padding-right: 95px;
  }

  .payment-container .adj-c {
    max-width: 290px;
  }

  .payment-container .adj-f {
    max-width: 312px;
  }

  .payment-container .adj-d {
    max-width: 140px;
  }

  .row.g-2.adj-c img {
    width: 100%;
    max-width: 72px;
  }
}

@media (max-width: 1600px) {
  .bottom-box .right-bottom {
    display: block;
    max-width: 50%;
  }
}

@media (max-width: 1400px) {
  .bottom-box {
    display: inline-block;
    width: 100%;
    max-width: 100%;
  }

  .resp-table {
    width: 100%;
    overflow-x: scroll;
  }

  .bottom-box .left-bottom {
    display: block;
    max-width: 100%;
    padding: 0 0px 0px 0;
    width: 100%;
    float: none;
    margin-right: 0;
  }

  .bottom-box .right-bottom {
    display: block;
    max-width: 100%;
    margin-left: 0;
  }

  .payment-container {
    max-width: 610px;
    margin: 0 auto;
  }
}

@media (max-width: 1200px) {
  .payment-container header .col-xl-6 {
    margin-bottom: 25px;
  }
}

@media (max-width: 500px) {
  .login .form {
    max-width: none;
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
    padding: 25px 15px 20px 20px;
  }

  .login-form .form h2 {
    font-size: 20px;
  }

  .login .form .submit {
    background: #011c40 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #00001f;
    width: 100%;
    color: #fff;
    text-align: center;
    font: normal normal bold 22px/27px Poppins;
    letter-spacing: 0px;
    height: 44px;
    border-radius: 10px;
    font-size: 14px;
    line-height: normal;
  }

  .login .reset-tab {
    height: 43px;
    background: #b4975a 0% 0% no-repeat padding-box;
    text-align: center;
    color: #fff;
    text-align: center;
    font: normal normal bold 22px/27px Poppins;
    width: 100%;
    display: block;
    border-radius: 10px;
    line-height: 45px;
    font-size: 11px;
    text-transform: uppercase;
    text-decoration: none;
  }

  .forgot-password a {
    padding: 10px 0;
    font: normal normal 600 11px/25px Poppins;
    letter-spacing: 0px;
    text-decoration: none;
    color: #202020;
    font-size: 11px;
  }

  .forgot-password a.second-link {
    font: normal normal bold 11px/25px Poppins;
    letter-spacing: 0px;
    color: #b4975a;
    text-decoration: underline;
    font-size: 11px;
  }
}

/* FOOTER cSS STARTS HERE */

r textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.error-message {
  font-size: 14px;
  line-height: normal;
  color: #91232c;
  padding-top: 10px;
}

button.active_Billing {
  margin: 0 10px;
  font-size: 2rem;
  border-radius: 100px;
  color: var(--darkblue);
  background-color: transparent;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.inactive_Billing {
  margin: 0 10px;
  font-size: 2rem;
  border-radius: 100px;
  color: var(--text);
  background-color: transparent;
  border: none !important;
}

.data-box .icon img {
  width: 50%;
}

/* Property Search tabs design */
.react-tabs__tab-list {
  border: none !important;
}

.react-tabs__tab-list {
  background-color: #fff;
  margin: 0 !important;
  padding: 0 10px !important;
  padding-left:0 !important;
  padding-left: 0;
}

.tabs_top_main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-bottom: 1.5px solid #aaa;
}

.tabs_top_main_address {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-top: 1.5px solid #aaa;
  margin-top: 46px;
  padding-top: 10px;
}

.biling_title {
  padding: 13px 10px;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0;
  color: var(--blue);
  background-color: #fff;
}

.react-tabs__tab-panel.search {
  padding: 10px;
  min-height: 80px !important;
}

.react-tabs__tab-panel {
  background-color: #fff;
  padding: 20px 0;
  /* min-height: 500px; */
  height: auto;
}


.react-tabs__tab-list .react-tabs__tab {
  margin-right: 8px;
  padding: 10px 0px;
  margin: 0 25px;
  min-width: auto;
  border-radius: 0;
  border: 0;
  text-align: center;
  font-size: 1rem;
  color: var(--gray500);
  text-transform: capitalize;
  font-weight: 500;
}
.react-tabs__tab-list .react-tabs__tab:first-child {
  margin-left: 0 !important;
}

.react-tabs__tab-list .react-tabs__tab:hover {
  color: var(--darkblue) !important;
  border-color: var(--darkblue) !important;
}

.react-tabs__tab-list .react-tabs__tab svg {
  margin-right: 5px;
  font-size: 1rem;
}

/* .react-tabs__tab-list .react-tabs__tab:nth-child(1){
  font-weight: 600;
  font-size: 1rem;
} */
/* .react-tabs__tab-list .react-tabs__tab:nth-child(1).react-tabs__tab--selected{
  color: var(--blue) !important;
  border-bottom: none !important;
} */
.react-tabs__tab--selected {
  color: var(--blue7) !important;
  border-bottom: 3px solid var(--blue7) !important;
  font-weight: 600 !important;
}

.react-tabs__tab:focus:after {
  content: none;
}

/* billing listing label */
.label_active {
  padding: 3px 10px;
  background-color: #28a745;
  color: #fff;
  font-weight: 600;
  border-radius: 20px;
  font-size: 0.8rem;
}

.label_Inactive {
  padding: 3px 10px;
  background-color: #bd2130;
  color: #fff;
  font-weight: 600;
  border-radius: 20px;
  font-size: 0.8rem;
}

/* selct 2 slide to top */
.css-1nmdiq5-menu {
  z-index: 999 !important;
}

/* filter design */
.filter_div {
  width: 80%;
  margin: 0 auto;
  border: 1px solid var(--text);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50px;
  overflow: hidden;
  height: 40px;
}

.filter_div .form-control {
  border: none !important;
  background-color: transparent !important;
  height: 100%;
  padding-top: 10px;
  text-align: center;
  font-size: 0.9rem !important;
}

.filter_div .btn {
  margin-top: 0px;
  border-radius: 50px !important;
  height: 100% !important;
}

#page-content-wrapper .btn-group {
  background-color: #fff;
  width: 100%;
  border-radius: 8px !important;
  flex-wrap: wrap;
}

#page-content-wrapper .btn-group .btn {
  padding: 10px;
  border-radius: 8px !important;
  margin: 0 5px;
  background-color: transparent !important;
  color: var(--gray500) !important;
  position: relative;
  font-weight: 500;
}

#page-content-wrapper .btn-group .btn::after {
  /* content: ''; */
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 50%;
  background-color: var(--darkblue);
}

#page-content-wrapper .btn-group .btn:last-child::after {
  content: none;
}

#page-content-wrapper .btn-group .btn.active {
  background-color: var(--gray25) !important;
  margin: 0;
  color: var(--darkblue) !important;
  font-weight: 600 !important;
}

/* invoice_table */
.invoice_table {
  padding: 20px 0;
}

.invoice_table h3 {
  font-size: 1.2rem;
  color: var(--darkblue);
  font-weight: 500;
  margin-bottom: 0;
  padding-left: 10px;
}

.invoice_table h6 {
  font-size: 1rem;
  padding-left: 10px;
  color: var(--text);
}

.form-check-input {
  background-color: var(--bg);
  border-color: var(--darkblue) !important;
}

.form-check-input:checked {
  background-color: var(--blue) !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.form-check-input {
  width: 17px;
  height: 17px;
}

/* property search input  */
.css-pa51dx-control {
  border-color: var(--darkblue);
}

.css-tj5bde-Svg {
  color: var(--darkblue) !important;
}

@media (max-width: 380px) {
  .mian_filter_class {
    flex-direction: column;
  }

  .main-header {
    padding-left: 0;
  }

  .containerOfFilter {
    width: 100%;
    height: auto;
  }

  .iconRemove_icon_div {
    width: 100%;
    height: 30px;
  }

  .iconRemove_icon_div {
    width: 92%;
    height: 30px;
  }

  .iconRemove_icon_div .iconRemove_icon {
    font-size: 1.2rem;
    transform: rotate(180deg);
  }

  .iconRemove_icon_div_rotate {
    width: 92%;
    height: 30px;
  }

  .iconRemove_icon_div_rotate .iconRemove_icon {
    font-size: 1.2rem;
    transform: rotate(0deg);
  }

  .slideContainer {
    margin-top: -120%;
    margin-left: 0 !important;
    height: auto;
    max-height: 400px;
  }

  .slideContainer.slideShow {
    margin-top: 0%;
  }

  .filestyle {
    flex-direction: column;
  }

  .filestyle p {
    margin-top: 10px;
    text-align: center;
  }

  #page-content-wrapper {
    padding: 20px !important;
  }

  .resp-table2 {
    overflow-x: scroll;
  }

  .filterNav {
    padding: 0 !important;
    margin-bottom: 20px;
    flex-wrap: wrap !important;
  }



  .tabs_top_main_address {
    margin-top: 0 !important;
  }

  .react-tabs__tab-list .react-tabs__tab {
    padding: 5px 25px !important;
    width: 100% !important;
    text-align: left;
  }

  .header-notification .notify-dropdown {
    width: 100% !important;
  }
}

@media (min-width: 381px) and (max-width: 480px) {
  .header-notification .notify-dropdown {
    width: calc(100%) !important;

  }

  .filestyle {
    flex-direction: column;
  }

  .filestyle p {
    margin-top: 10px;
    text-align: center;
  }

  #page-content-wrapper {
    padding: 10px !important;
  }

  .resp-table2 {
    overflow-x: scroll;
  }

  /* quick filter */
  .mian_filter_class {
    flex-direction: column;
  }



  .containerOfFilter {
    width: 100%;
    height: auto;
  }

  .iconRemove_icon_div {
    width: 100%;
    height: 30px;
  }

  .iconRemove_icon_div {
    width: 100%;
    height: 30px;
  }

  .iconRemove_icon_div_rotate {
    width: 100%;
    height: 30px;
  }

  .iconRemove_icon_div_rotate .iconRemove_icon {
    font-size: 1.2rem;
  }

  .iconRemove_icon_div_rotate .iconRemove_icon {
    font-size: 1.2rem;
  }

  .slideContainer {
    height: auto;
    max-height: 400px;
  }

  /* 
  .filterNav {
    padding: 0 !important;
    margin-bottom: 20px;
  } */



  .filterNav button svg {
    margin: 0 !important;
  }

  .tabs_top_main_address {
    margin-top: 0 !important;
  }

  .react-tabs__tab-list .react-tabs__tab {
    padding: 5px 25px !important;
    width: 100% !important;
    text-align: left;
  }


}

/* ************************** */
@media (min-width: 480px) and (max-width: 576px) {
  .filestyle {
    flex-direction: column;
  }

  .filestyle p {
    margin-top: 10px;
    text-align: center;
  }

  .resp-table2 {
    overflow-x: scroll;
  }

  .react-tabs__tab-list .react-tabs__tab {
    padding: 5px 25px !important;
    width: 100% !important;
    text-align: left;
  }



  /* quick filter */
}

/* ************************** */
@media (min-width: 576px) and (max-width: 786px) {
  .filestyle {
    flex-direction: column;
  }

  .filestyle p {
    margin-top: 10px;
    text-align: center;
  }

  .resp-table2 {
    overflow-x: scroll;
  }

  .filterNav {
    padding: 0 !important;
    margin-bottom: 20px;
  }


  .filterNav button {
    padding: 10px 20px !important;
  }

  .tabs_top_main_address {
    margin-top: 0 !important;
  }


}

@media (min-width: 768px) and (max-width: 991px) {
  .filestyle {
    flex-direction: column;
  }

  .filestyle p {
    margin-top: 10px;
    text-align: center;
  }

  .resp-table2 {
    overflow-x: scroll;
  }

  /* quick filter */
}

/* ************************** */
@media (min-width: 992px) and (max-width: 1024px) {
  .filestyle {
    flex-direction: column;
  }

  .filestyle p {
    margin-top: 10px;
    text-align: center;
  }

  .resp-table2 {
    overflow-x: scroll;
  }
}

/* data picher */
.react-datepicker__month-container {
  width: 300px;
}

.react-datepicker__header {
  background-color: var(--darkblue);
  color: #fff;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #fff !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2em !important;
  line-height: 2rem !important;
}

.react-datepicker__day-name:hover {
  background-color: #fff;
  color: var(--darkblue) !important;
  border-radius: 5px;
}

.react-datepicker__day-name {
  color: #fff !important;
}

.react-datepicker__month-select {
  padding: 5px;
  border-radius: 5px;
}

.react-datepicker__year-select {
  padding: 5px;
  border-radius: 5px;
}

.react-datepicker__month {
  background-color: #fff;
}

.react-datepicker__month .react-datepicker__week:nth-child(even) {
  background-color: #f4f7fc !important;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  background-color: var(--darkblue);
  color: #fff !important;
  border-radius: 5px;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: var(--darkblue);
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--darkblue);
  color: #fff !important;
}

.showRecordText {
  font-size: 0.7rem;
}

.showRecordText span {
  color: var(--darkblue);
  font-weight: 700;
}

/* Steps Design */
.step_head {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 20px;
}

.step_head li {
  padding: 5px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-right: 10px;
  width: 200px;
  color: var(--darkblue);
  font-weight: 600;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

@media (max-width: 480px) {
  .step_head li {
    width: 150px;
  }
}

.step_head li.complete {
  background-color: var(--yellow);
  color: #fff;
}

.step_head li.complete svg {
  color: var(--darkblue);
  background-color: #fff;
}

.step_head li.current {
  background-color: var(--darkblue);
  color: #fff;
}

.step_head li.current svg {
  color: var(--darkblue);
  background-color: #fff;
}

.step_head li svg {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: var(--darkblue);
  color: #fff;
  padding: 10px;
}

/* google map div */
.mapboxgl-canvas {
  width: 100% !important;
}

/* Date picker */
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range) {
  background-color: var(--darkblue) !important;
  color: #fff !important;
}

.css-18fyn3m-control,
.css-a343tj-control {
  border-radius: 10px !important;
  /* border-color: var(--darkblue) !important; */
  font-size: 0.8rem !important;
  background-color: var(--lightsky) !important;
  height: 45px !important;
}

.css-qbdosj-Input {
  margin: 0 !important;
  padding: 0 !important;
}

.css-1jqq78o-placeholder {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--darkblue) !important;
}

.css-a343tj-control:hover {
  border-color: var(--darkblue) !important;
}

/* property search */
.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  border-radius: 10px !important;
}

.css-3d1h8d-control {
  background-color: transparent !important;
}


.css-1dimb5e-singleValue {
  color: var(--blue) !important;
  font-size: 1rem !important;
}

/* Success Section */
.success_div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.success_div svg {
  margin-bottom: 20px;
  font-size: 4rem;
  color: var(--yellow);
}

.placeholder {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--darkblue);
}

/* Active account page */
.success_activePage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #0f5132;
  border-radius: 10px;
  background-color: #80dab05b !important;
}

.success_activePage svg {
  margin-bottom: 20px;
  font-size: 4rem;
  color: #0f5132 !important;
}

.success_failPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #0f5132;
  border-radius: 10px;
  background-color: #c1545f75 !important;
}

.success_failPage svg {
  margin-bottom: 20px;
  font-size: 4rem;
  color: #58151c !important;
}

.filter_divider {
  display: block;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 5px 20px;
  border-radius: 5px;
  margin-top: 10px;
  /* color: var(--darkblue);
  background-color: #fff2d6; */

  background-color: var(--gold);
  color: #fff;
}

.filter_head {
  font-size: 0.9rem;
}

.defined_filter {
  overflow-y: scroll;
  margin: 5px 0;
  height: 380px;
  padding: 5px;
}

.defined_filter::-webkit-scrollbar {
  display: none;
}

.defined_filter ul li.link-item {
  background-color: #eee;
  color: #000;
  font-size: 0.7rem;
  padding: 23px 10px 10px 10px;
  box-shadow: none;
  border-radius: 5px;
  margin: 5px 0;
  line-height: 15px;
  position: relative;
}

.defined_filter ul li.link-item svg {
  position: absolute;
  top: 0px;
  right: 0px;
  color: var(--text);
  font-size: 1.6rem;
  cursor: pointer;
}

.defined_filter ul li.link-item span {
  display: block;
  font-weight: 600;
  background-color: #fff;
  color: var(--lighblue);
  padding: 5px 10px;
  margin: 10px 0;
  border-radius: 5px;
}

.filter_btn {
  background: #0b2548;
  color: white;
  width: 100%;
  border-radius: 100px;
  padding: 6px 20px;
  font-size: 1.1rem;
  border: 0;
  transition: 0.5s;
}

.filter_btn:hover {
  background-color: var(--yellow);
}

.setting_form {
  border: 0;
}

.setting_form .uploadPreview {
  padding: 0;
  margin: 0;
  width: 105px;
  height: 105px;
  float: left;
  margin-right: 30px;
  border-radius: 100px;
}

.setting_form .overlay_setting {
  float: left;
  padding: 0 10px;
}

.setting_form h4.title-sub {
  color: var(--subHeadColor);
  font-size: 24px;
  font-weight: 600;
}

.setting_form .form-check-label {
  font-size: 1rem;
  font-weight: 600;

}

.setting_form .form-label {
  font-weight: 500 !important;
  /* letter-spacing: 1px; */
  font-size: 16px;
  color: var(--gray900); 
}

.setting_form .form-control {
  font-size: 16px;
  padding: 10px 20px;
  /* background-color: var(--lightsky); */
  /* font-weight: 600 !important; */
  border-radius: 15px !important;
  border: 1px solid var(--gray300) !important;
  color: var(--gray900);
  font-weight: 500;
}

.setting_form .css-1dimb5e-singleValue {
  font-weight: 500;
  color: var(--gray900) !important;
}

.setting_form .form-control:focus {
  /* background-color: var(--lightsky) !important; */
  border: 1px solid var(--blue) !important;
}

/* Accordian */
.accordion__item .accordion__panel {
  background-color: #fff !important;
}

.modal_notification {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal_notification h5 {
  text-align: center;
  margin: 20px 0;
  color: var(--darkblue);
  font-size: 1rem;
  width: 80%;
}

.modal_notification .btn {
  margin: 0 5px;
}

.modal_notification_property {
  width: 100%;
}

.modal_notification_property h4 {
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--blue);
  margin: 20px 0;
}

.modal_notification_property input {
  /* border: 0 !important; */
  padding: 10px;
  color: var(--blue);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.modal_notification_property input::placeholder {
  color: var(--blue);
  font-weight: 500;
}

.modal_notification_property input:focus {
  /* border: 1px solid var(--blue) !important; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;

}

.modal_notification_property .btn {
  margin: 0 5px;
}

.modal_notification_property p {
  color: var(--blue);
  font-weight: 500;
}

.modal_notification_property p strong {
  color: var(--gold);
}

.modal_notification_property .text strong {
  color: var(--gold);
}

.modal_notification_property .text {
  color: var(--blue);
  font-weight: 500;
}

.modal-header {
  border-radius: 5px 5px 0 0;
  padding: 5px 1rem !important;
  background-color: #eee;
}

.modal-header .modal-title {
  border: none !important;
  font-size: 1rem !important;
}

.btn-icon {
  background-color: var(--darkblue);
}

.btn-icon svg {
  color: #fff;
  font-size: 1.2rem;
}

.btn-group-sm>.btn,
.btn-sm {
  padding: 0.2rem 0.25rem !important;
  border-radius: 50%;
}


/* Design of v3 */
.wedgot_col {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.pre-plan {
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan_des {
  font-size: 01rem;
  margin-bottom: 5px;
  color: var(--blue);
  font-weight: 500;
  text-align: center;
}

.plan_des svg {
  margin-right: 10px;
}

.payment_widget_botton {
  padding: 20px 10px;
  background-color: var(--blue);
  border-radius: 5px;
  margin: 20px 10px;
  width: 150px;
  height: 60px;
  color: #fff;
  outline: none;
  font-weight: 600;
  font-size: 1rem;
  border: none;
}

.payment_widget_botton.add {
  cursor: pointer;
  background-color: var(--gold) !important;
}

.payment_widget {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 10px;
  border: thin solid var(--blue);
  border-radius: 5px;
  margin-bottom: 20px;
  width: 200px;
  height: 100px;
}

.payment_widget h5 {
  color: var(--blue);
  font-size: 0.8rem;
  position: relative;
}

/* .payment_widget h5:after {
  content: '';
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 105%;
  height: 1px;
  background-color: #fff;
} */

.payment_widget h6 {
  font-size: 1rem;
  color: var(--blue);
  font-weight: 600;
  text-align: center;
}

.payment_widget.add {
  cursor: pointer;
  background-color: var(--blue) !important;
}

.payment_widget.add h5,
.payment_widget.add2 h5 {
  color: #fff !important;
  font-weight: 600;
  font-size: 1rem;
}

.payment_widget.add:active {
  /* background-color: var(--blue) !important; */
  box-shadow: inset 0 0 10px 0px #eee;
}

.payment_widget svg {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}


.payment_widget.add2 {
  cursor: pointer;
  background-color: var(--gold) !important;
}

.back_icon {
  width: 30px;
  height: 30px;
  background-color: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 20px;

}

.back_icon svg {
  color: #fff;
  font-size: 1.5rem;
}

.sub_title {
  font-weight: 700 !important;
  color: var(--blue);
  font-size: 1.7rem !important;
}

.billing_detail_section {
  width: 100%;
  border: 0;
  padding: 30px;
  margin-top: 30px;
  border-radius: 40px;
  background: var(--gray25);
  position: relative;
  top: -40px;
}

.addskip-card {
  background-color: transparent;
  border: 0;
}

body .addskip-card h6 {
  font-size: 18px;
  color: var(--gray900);
}
body .addskip-card p {
  width: 100%;
  text-align: left;
  color: var(--gray700);
  font-size: 16px;
  font-weight: 400;
}
body .addskip-card .submit_Payment {
  background: var(--darkblue);
  color: white;
  padding: 15px 0px;
  border-radius: 120px;
  width: 100%;
  font-size: 20px;
  margin: 20px 0;
  border: 0;
}
.submit_Payment:hover {
  background-color: var(--gold) !important;
  transition: all 0.3s;

} 
body .addskip-card span {
  display: block;
  width: 100%;
  text-align: right;
  padding: 0;
  margin: 0;
  background: transparent;
  color: var(--gray700);
  font-weight: 600;
}

.billing_detail_section .plan_select {
  background-color: var(--lightsky) !important;
  color: var(--blue);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 10px !important;
}

.billing_detail_section .plan_select:is(:hover, :focus) {
  box-shadow: none !important;
  border-color: var(--blue);
}

.skips-checkout {}
body .skips-checkout .font-lg {
  font-size: 30px !important;
}
.skips-checkout span {
  background-color: var(--yellow50);
  border-radius: 100px;
  text-align: center;
  float: left;
  margin-right: 20px;
  width: 35px;
  height: 35px;
  padding-top: 6px;
  color: var(--yellow900);
}
.form-label {
  font-weight: 500;
  color: var(--gray700);
}
h2 {
  font-size: 24px;
  color: var(--gray700);
  font-weight: 600;
}
.skips-checkout h3 {
  font-size: 24px;
  color: var(--darkblue);
  line-height: 1.4;
  font-weight: 600;
}
.font-weight600 {
  font-weight: 600 !important;
}
.skips-checkout h4.right {
  text-align: right !important;
}
.skips-checkout p {}
.skips-checkout input {
  border: 0;
  text-align: center;
}
.skips-checkout .sub_title_h4 {
  font-size: 34px;
}
.skips-checkout .input-group {
  float: left;
  max-width: 103px;
  margin: 0 10px;
}
.skips-checkout button.arthmetic {
  width: 40px;
  height: 40px;
  border: 0;
  font-weight: 100;
  border-radius: 10px;
  float: left;
}

.sub_title_h4 {
  font-weight: 600 !important;
  color: var(--blue);
  font-size: 1.2rem;
}

.sub_title_h42 {
  font-weight: 600 !important;
  color: var(--blue);
  font-size: 1.2rem;
  letter-spacing: 1px;
  text-align: right;
  margin-top: 30px;
}

.sub_title_h4.right {
  text-align: right;
}

.term_checkbox label p a {
  font-weight: 700;
  font-size: 0.9rem;
}


.billing_detail_section_des {
  width: 90%;
  margin: 30px 0 10px 0;
  font-size: 16px;
}

.billing_detail_section_des h4 {
  color: var(--gray700);
  font-weight: 400;
  font-size: inherit;
}

.billing_detail_section_des ul {
  padding-left: 1.2rem;
}

.billing_detail_section_des ul li {
  padding: 2px 0;
  font-size: inherit;
  color: var(--gray700);
  font-weight: 400;
}

.payment_detial_section {
  padding: 20px 0;
}

.payment_detial_section .input-group {
  border-radius: 0 !important;
}

.payment_detial_section .input-group .form-control {
  border-radius: 0 !important;
  background-color: var(--lightsky) !important;
  font-size: 0.9rem !important;
  color: var(--blue) !important;
  padding: 10px;
}

.payment_detial_section .input-group .form-control::placeholder {
  color: var(--blue);
}

.payment_detial_section .input-group .input-group-text {
  border-radius: 0 !important;
}

.payment_detial_section .d-grid.gap-2 .btn-primary {
  padding: 10px !important;
  border-radius: 0 !important;
}

.table_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #B7B7B7;
  margin-top: 10px;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

.table_head p {
  padding-bottom: 5px;
  font-weight: 500;
  margin: 0 !important;
}

.table_head p:nth-child(1) {
  width: 280px;
}

.table_body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.table_body span:nth-child(1) {
  width: 300px !important;
}

.table_body span {
  width: 130px;
  color: var(--blue);
  font-weight: 500;
  font-size: 0.9rem;
}

.table_body span:nth-child(2) {
  text-align: center;
}

.table_body span:nth-child(3) {
  text-align: right;
}

.sub_total_section {
  width: 100%;
  border-top: 2px dashed #B7B7B7;
  margin-top: 20px;
  letter-spacing: 2px;
}

.sub_total_section div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;

}

.sub_total_section div span {
  color: var(--blue);
  font-weight: 500;
}

.total_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #B7B7B7;
  border-bottom: 1px solid #B7B7B7;
  padding: 5px 0;
  letter-spacing: 2px;
}

.total_section span {
  font-weight: 700;
  color: var(--blue);
}

.term_checkbox {
  margin: 25px 0;
}

.term_checkbox label {
  font-size: 0.9rem;
  color: var(--gray700);
  font-weight: 400;
}

.billing_detail_section .btn-warning {
  border-radius: 0px !important;
  font-size: 1rem;
  padding: 10px 20px;
  font-weight: 600;
  letter-spacing: 2px;
  transition: all linear 0.2s;
}

.btn-warning:hover {
  background-color: var(--blue) !important;
}


.term_checkbox {
  background-color: var(--bg);
  border-color: var(--darkblue) !important;
}

.term_checkbox:checked {
  background-color: var(--yellow) !important;
  border-color: var(--yellow) !important;
}

.term_checkbox:focus {
  box-shadow: none !important;
}

.term_checkbox {
  border-radius: 0px !important;
}

.billing_detail_section .form-control {
  background-color: var(--lightsky);
  border: 0 !important;
  border-radius: 0 !important;
  color: var(--blue);
  font-weight: 600;
  text-align: center;
}

.billing_detail_section .form-control:is(:hover, :focus) {
  background-color: var(--lightsky) !important;
}

.addition {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 !important;
  height: 40px;
  overflow: hidden;
  background-color: var(--lightsky);
  border-radius: 0;
  border: 0;
}

.addition button:nth-child(1) {
  /* margin-top: -5px; */
  margin-bottom: 3px;
}

.addition button {
  border: 0;
  width: 100%;
  padding: 0 5px;
  outline: 0;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.addition button svg {
  color: var(--blue);
  font-weight: 600;
}

/* Inoive */
.customer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.customer .image_div {
  width: 120px;
  height: 120px;
  margin-right: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.customer .image_div img {
  border-radius: 10px;

}

.customer h5 {
  font-weight: 600 !important;
  color: var(--blue);
  font-size: 1rem !important;
  letter-spacing: 1px;
}

.customer .customer_info {
  width: 80%;
}

.customer ul {
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}


.customer .customer_info li a,
.customer .customer_info a {
  color: var(--text);
}


.customer p {
  margin: 0;
}

.divider {
  width: 100%;
  height: 7px;
  border-radius: 20px;
  background: transparent linear-gradient(90deg, #0C2648 0%, #214C84 18%, #4C7BB7 43%, #EDD9B0 84%, #D4AF62 100%) 0% 0% no-repeat padding-box;
}

.info_type {
  font-weight: 600;
  color: var(--blue);
  margin: 20px;

}

.date_section {
  margin-bottom: 30px;
}

.date_head {
  color: var(--blue);
  font-size: 1.8rem;
}

.date_section2 {
  margin-left: 40px;
}

.date_section h5,
.date_section2 h5 {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0;
  color: var(--blue);
}

.deital_head {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deital_head.border_bottom {
  border-bottom: 2px solid var(--blue);
}

.deital_head h6 {
  font-weight: 600;
}

.deital_head h6:nth-child(1) {
  width: 230px;
  text-align: left;
}

.deital_head h6:nth-child(3) {
  width: 120px;
  text-align: right;
}

.hr_devider {
  width: 100%;
  border: 1.5px dashed #C2C2C2 !important;
}

.sub_div_property {
  background-color: #fff;
  padding: 10px;
}

.glassModel {
  background: rgba(255, 255, 255, 0.17) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.7px);
  border-radius: 10px !important;
  -webkit-backdrop-filter: blur(5.7px);
  border: 1px solid rgba(255, 255, 255, 0.04) !important;
}

.glassModel .modal-content {
  background: white !important;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.3px);
  -webkit-backdrop-filter: blur(5.3px);
  border: 1px solid rgba(255, 255, 255, 0.04) !important;
  overflow: hidden;
}

.glassModel .modal-content .modal-body {
  border-radius: 16px;

}

.glassModel .modal-content svg {
  cursor: pointer;
  color: var(--blue);
}

.modal-content svg:hover {
  color: var(--gold);
}

.glassModel #page-content-wrapper {
  min-height: auto;
}

/* Payment method */
.checkbox_main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkbox_main p {
  margin: 0 !important;
  font-weight: 500;
  font-size: 0.9rem;
}

.checkbox_main .img_checkbox_div {
  width: 40px;
  min-height: 20px;
  background-color: #EFFAFF;
  padding: 3px 5px;
  box-sizing: border-box;
  border: 1px solid #A5A5A5;
  border-radius: 5px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}

.checkbox_main .img_checkbox_div:hover {
  background-color: #cbeeff;
}

.checkbox_main .img_checkbox_div svg {
  color: var(--blue);
}

.icon_div {
  background-color: var(--blue);
  padding: 10px;
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  position: relative;
  transition: all 0.3s;
}

.icon_div:hover {
  opacity: 0.8;
}



.css-1fdsijx-ValueContainer {
  margin: 0 !important;
  padding-left: 20px !important;
}

/* .searchAddress .react-tabs__tab-panel{
  border: 1px solid red;
  height: 200px !important;
} */
.searchAddress .tabs_top_main .react-tabs__tab-list {
  width: 100% !important;
}

/* .searchAddress .tabs_top_main .react-tabs__tab-list .react-tabs__tab {
  width: 31.7% !important;
} */

.message_show {
  margin: 0 auto;
  width: 50%;
  text-align: center;
  padding: 8px 10px;
  background: var(--lightsky);
  border-radius: 10px;
  color: var(--gold);
  font-weight: 600;
}

.meesage_icon {
  width: 80px;
  height: 80px;
  padding: 10px;
  /* background-color: var(--gold); */
  /* border: 1px solid var(--gold); */
  color: var(--gold);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  border-radius: 100px;

}

.meesage_icon svg {
  font-size: 5rem;
}

.newsletter_message_modal {
  text-align: center;
}

.newsletter_message_modal h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--gold);
}

.newsletter_message_modal h1 {
  font-size: 1.8rem;
  line-height: 1.4;
  color: var(--blue);
}

/* for dashbaod modal */
.newsletter_message_modal h2 {
  font-weight: 700;
  color: var(--blue);
  letter-spacing: 1;
  margin-top: 30px;
}

/* Terms and Condition */
.terms_and_condition {
  padding: 20px;
  background-color: var(--lightsky);
  border-top: 1px solid var(--blue);
}

.terms_and_condition .term_box {
  margin: 10px 0;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #fff;
}

.terms_and_condition .term_box ul {
  padding-left: 40px;
}

.terms_and_condition .term_box h4 {
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--blue);
}

.terms_and_condition .term_box p {
  padding-left: 10px;
  font-weight: 400;
  margin-bottom: 5px;
}

.terms_and_condition .term_box p span {
  color: var(--blue);
  font-weight: 500;
  margin-right: 5px;
}

.require_span {
  font-size: 0.9rem !important;
}

.main-form-register {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-form-register-sub {
  width: 70%;
}

.table-data-main.property.resp-table2 {
  max-height: 600px !important;
  overflow: hidden scroll;
}

.dashboard_footer {
  background-color: var(--bgTheme);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 40px;
  border-top: 1px solid var(--blue);
}

.dashboard_footer a {
  margin-left: 5px;
  color: var(--blue);
  font-weight: 600;
}

.dashboard_footer a:hover {
  color: var(--gold);
}

/* skip trace modal design */
.skip_modal_title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  color: var(--blue);
  font-weight: 700;
  line-height: 35px;
  margin-top: 30px;
}

.skip_offer_text {
  font-size: 2.2rem;
  font-weight: 700;
  color: var(--gold);
  text-align: center;
  margin: 10px 0 0 0;
}

.skip_des_text {
  text-align: center;
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--blue);
  width: 70%;
  margin: 0 auto;
  line-height: 23px;
}

.skip_span_text {
  display: block;
  margin-top: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
}

.glassModel .modal-xl {
  max-width: 80% !important;
}

.glassModalSkips.glassModel .modal-xl {
  /* max-width: 70% !important; */
  margin-right: 5% !important;
}

.skip_trace_card {
  width: 100%;
  border: 4px solid var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.skip_trace_card .skip_discout_title {
  width: 100%;
  height: 60px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skip_trace_card .skip_discout_title span {
  font-size: 1.4rem;
  font-weight: 600;
}

.skip_trace_card .skip_number {
  width: 100%;
  height: 80px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue);
}

.skip_trace_card .skip_number h4 {
  color: #fff;
  font-weight: 600;
}

.skip_trace_card .price {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.skip_trace_card .price p {
  font-size: 2.5rem;
  color: var(--blue);
  font-weight: 600;
  margin-bottom: 0;
}

.skip_trace_card .price p sup {
  margin-right: 5px;
}

.skip_trace_card .price p small {
  font-weight: 500;
  font-size: 1.2rem;
}

.skip_trace_card .skip_button {
  width: 60%;
  border-radius: 30px;
  padding: 10px 20px;
  margin: 5px 0 30px 0;
  background-color: var(--blue);
  color: #fff;
  font-weight: 600;
  border: 0;
  outline: 0;
  transition: 0.2s linear;
}

.skip_trace_card .skip_button:hover {
  background-color: #ba964f !important;
  color: #fff !important;
}

/*  */
.btn-group .warning {
  background: #d4af62 !important;
  color: #fff;
  border-radius: 100px !important;
  font-size: 1rem;
}

.badge-bg-primary {
  background-color: var(--gold) !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 43px;
}

.accordion-button::after {
  background-image: url('./../img/icon/arrow-down.png');
}

.accordion-button:not(.collapsed)::after {
  background-image: url('./../img/icon/arrow-down.png');
  /* background-image: url('../img/icon/arrow-up.png'); */
}


p.payment-date-bill {
  color: var(--gray500);
  font-weight: 500;
}
.radio-item [type="radio"] {
  display: none;
}

.radio-item {
  margin-top: 15px;
}

.radio-item label {
  display: block;
  padding: 8px 20px;
  background-color: var(--lightsky);
  border-radius: 8px;
  cursor: pointer;
  font-size: var(--pb);
  min-width: 100%;
  max-width: 100%;
  white-space: nowrap;
  position: relative;
  transition: 0.4s ease-in-out 0s;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400 !important;
  font-size: 1.1rem !important;

}


.radio-item label svg {
  margin: 0 10px 0 5px;
  font-size: 1.8rem;
  color: var(--blue);
  /* transform: rotate(180deg); */
}

.radio-item label:after,
.radio-item label:before {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.radio-item label:after {
  height: 18px;
  width: 18px;
  border: thin solid var(--blue);
  right: 19px;
  top: calc(50% - 10px);
}

.radio-item label:before {
  background: var(--blue);
  content: url("data: image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' %3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m6 10 3 3 6-6' /%3E%3C/svg%3E");
  height: 18px;
  width: 18px;
  right: 19px;
  top: calc(50%-2px);
  transform: scale(1.2);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s linear;
}

.radio-item [type="radio"]:checked~label {
  border: thin solid var(--blue) !important;
}

.radio-item [type="radio"]:checked~label::after,
.radio-item [type="checkbox"]:checked~label::after,
.radio-item-simple [type="checkbox"]:checked~label::after {
  border: none;
}

.radio-item [type="radio"]:checked~label::before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.email_verification_page {
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* min-height: 800px; */
  height: 100%;
  padding: 60px;
  flex-direction: column;
}

.email_verification_page h1 {
  margin: 40px 0;
  font-size: 2.2rem;
  color: var(--blue);
  text-transform: capitalize;
  font-weight: 600;
  text-align: center;
}

.email_verification_page p {
  color: var(--blue);
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0px;
  text-align: center;
  padding: 0 20px;
  line-height: 2;
}

.email_verification_page p a {
  text-decoration: underline;
  color: var(--blue);
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 5px;
  text-align: center;
  padding: 0 20px;
}

.email_verification_page h3 {
  color: var(--blue);
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.third_des {
  margin: 50px 0;
}

.thankyou_text, .thankyou_text > a {
  font-size: 17px !important;
  font-weight: 600;
  color: var(--blue);
  line-height: 25px;
}
.thankyou_text > button {
  padding: 8px 80px;
  border-radius: 6px !important;
}
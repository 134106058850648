@media (min-width: 1351px) and (max-width: 1460px) {
  .data-box h2 {
    font-size: 1rem !important;
  }

  .sub-data-box .data-div h4 {
    font-size: 1rem !important;
  }

  .data-box p {
    font-size: 1rem !important;
  }

  .data-box.affiliate {
    height: 100% !important;
  }

  .overlay_setting {
    /* width: 200px !important; */
  }

  .react-tabs__tab-list .react-tabs__tab {
    min-width: auto !important;
  }

  .data-box p {
    font-size: 1rem !important;
  }

  .filterNav button {
    padding: 6px 15px !important;
  }

  .filterNav button svg {
    margin-right: 5px !important;
  }

  .chart-container canvas {
    height: 500px !important;
  }

  .setting_form .form-label {
    font-size: 0.8rem !important;
  }

  /* .select_plan {
    font-size: 0.9rem !important;
  } */

  .billing_detail_section .btn-warning {
    border-radius: 0px !important;
    font-size: 1rem !important;
    padding: 10px 20px !important;
    font-weight: 600;
    letter-spacing: 2px;
    
  }

  .signup_image_div h4 {
    font-size: 3.5rem !important;
  }


}

@media (min-width: 1201px) and (max-width: 1350px) {
  .pricing_item .pricing_item_price .pricing .amount {
    font-size: 2.2rem !important;
  }

  .data-box h2 {
    font-size: 1rem !important;
  }

  .downgrad_plan_btn {
    font-size: 0.9rem !important;
  }


  .pricing_item .pricing_item_price .pricing .type {
    font-size: 1rem !important;
  }

  .select_plan {
    font-size: 0.9rem !important;
    padding: 10px !important;
  }

  .disabled_select_plan {
    font-size: 0.9rem !important;
    padding: 10px 15px !important;
  }

  .react-tabs__tab-list .react-tabs__tab {
    min-width: auto !important;
    margin-right: 5px !important;
  }

  .filterNav {
    padding: 5px 20px !important;
  }

  .filterNav button {
    padding: 6px 10px !important;
  }

  .filterNav button svg {
    margin-right: 0px !important;
  }

  .signup_image_div h4 {
    font-size: 3rem !important;
  }
  .main-header .menu_toggle_icon h2 {
    font-size: 25px;
  }
}

@media(max-width:1500px) {
  body .header-notification .avatar .text-wrap {
    display: none;
  }
  
  body p.payment-date-bill {
    font-size: 14px;
  }
}

@media(min-width:1500px) {
  .sidebar.active .scrollable-xs {
    overflow-y: hidden !important;
  }
}


@media (max-width: 1200px) { 

  .datePic {
    /* width: 100% !important; */
    padding: 3px 10px !important;
  }

  .range-cst .text-end {
    text-align: left !important;
  }

  .react-tabs__tab-list .react-tabs__tab {
    min-width: auto !important;
    margin-right: 5px !important;
  }

  .filterNav {
    padding: 5px 20px !important;
  }

  .filterNav button {
    padding: 6px 10px !important;
  }

  .filterNav button svg {
    margin-right: 0px !important;
  }

  .chart-container canvas {
    height: 500px !important;
  }


  body .main-header {
      padding: 15px 0px;
  }
  body .main-title-mobile {
    margin: 0;
    margin-bottom: 15px;
  }


}

/*  XL Screen */
@media (min-width: 1025px) and (max-width: 1200px) {
  .datePic {
    width: 100% !important;
  }

  .react-tabs__tab-list .react-tabs__tab {
    min-width: auto !important;
    margin-right: 5px !important;
  }

  .filterNav {
    padding: 5px 20px !important;
  }

  .filterNav button {
    padding: 6px 10px !important;
  }

  .filterNav button svg {
    margin-right: 0px !important;
  }

  .chart-container canvas {
    height: 500px !important;
    margin-right: 0px !important;
  }

  .chart-container canvas {
    height: 500px !important;
  }

  /* email */
  .email_verification_page {
    padding: 30px !important;
    /* height: auto !important; */
  }

}
 

@media (width:912px) {

  .signup_image_div {
    height: 800px !important;
  }

  .signup_image_div .text-div {
    top: 300px !important;
  }
}

@media (width:853px) {

  .signup_image_div {
    height: 800px !important;
  }

  .signup_image_div .text-div {
    top: 300px !important;
  }
}

/*  LG Screen */
@media (min-width: 992px) and (max-width: 1024px) {
  .signup_image_div h4 {
    font-size: 2.3rem !important;
  }

  .duration_button_section .duration_btn {
    min-width: 140px !important;
    margin-bottom: 10px;
    font-size: 0.7rem !important;
  }

  .duration_button_section .duration_btn.annually {
    margin-left: -30px !important;
  }

  .pricing_item .pricing_item_price .pricing .amount {
    font-size: 3rem !important;
  }

  .select_plan {
    font-size: 1rem !important;
  }

  .details {
    width: 100% !important;
  }

  .details h4 {
    font-size: 1rem !important;
  }

  .details ul li {
    padding: 2px 0 !important;
    font-size: 0.7rem !important;
  }

  .table-data-main.resp-table2 {
    overflow: scroll !important;
  }

  .react-tabs__tab-list .react-tabs__tab {
    min-width: auto !important;
    margin-right: 5px !important;
    font-size: 0.7rem !important;
  }

  .tabs_top_main_address {
    margin-top: 46px !important;
    margin-bottom: 20px;
    /* border: 0 !important; */
    padding: 0 !important;
  }

  .filterNav button {
    padding: 8px 15px !important;
  }

  .filterNav {
    justify-content: center !important;
  }

  .chart-container canvas {
    height: 400px !important;
  }

  .filterNav {
    padding: 5px 20px !important;
  }

  .filterNav button {
    padding: 6px 10px !important;
  }

  .filterNav button svg {
    margin-right: 0px !important;
  }

  .chart-container canvas {
    height: 500px !important;
  }

  /* email */
  .email_verification_page {
    padding: 20px !important;
    /* height: auto !important; */
  }

  /* email */
  .email_verification_page p {
    padding: 0 !important;
  }

}

@media (width:810px) {
  .signup_image_div .text-div {
    margin-bottom: 200px;
  }

}

/*  MD Screen */
@media (min-width: 768px) and (max-width: 991px) {
  body .setting_form h4.title-sub {
    font-size: 20px;
  }
  .avatar .text-wrap {
    display: none;
  }
  .navbar-light .navbar-nav.right-nav .nav-link::after {
    left: 0 !important;
  }

  .main-middle-section .t-box {
    height: auto !important;
  }

  /* home page main bottom sectio */
  .image_div_top::after {
    content: "";
    width: 200px !important;
    height: 200px !important;
  }

  .react-tabs__tab-panel.search {
    height: auto !important;
  }

  .table-data-main.resp-table2 {
    overflow: scroll !important;
  }

  .tabs_top_main_address {
    margin-top: 0 !important;
    margin-bottom: 20px;
    border: 0 !important;
    padding: 0 !important;
  }

  .filterNav button {
    padding: 8px 15px !important;
  }

  .filterNav {
    justify-content: center !important;
  }

  .react-tabs__tab-list .react-tabs__tab {
    font-size: 0.9rem !important;
  }

  .chart-container canvas {
    height: 400px !important;
  }
}

/*  SM Screen */
@media (min-width: 576px) and (max-width: 767px) {
  .sidebar ul .logo img.bar-closed {
    display: none;
  }
  .navbar-light .navbar-nav.right-nav .nav-link::after {
    left: 0 !important;
  }

  .desktop {
    flex-direction: row !important;
  }

  .navbar-light .navbar-nav.menu_nav .nav-link {
    padding: 1rem !important;
  }

  .main-top-section {
    margin-top: 30px !important;
    padding: 10px !important;
  }

  .main-middle-section .t-box {
    width: 70% !important;
    margin: 0 auto;
  }

  /* home page main bottom sectio */
  .image_div_top::after {
    content: "";
    width: 200px !important;
    height: 200px !important;
  }

  .table-data-main.resp-table2 {
    overflow: scroll !important;
  }

  .message_show {
    width: 90% !important;
    font-size: 0.8rem !important;
    line-height: 1.4 !important;
  }

  /* Affiliation Page */
  .affiliate_card .input-group .form-control {
    margin-bottom: 10px;
    width: 100%;
  }

  .affiliate_card .input-group {
    flex-direction: column;
  }

  .chart_card {
    margin: 10px 0;
    padding: 10px !important;
  }

  .chart_card .chart_card_number {
    padding: 10px !important;
  }

  .chart-container canvas {
    height: 300px !important;
  }

  .table_head p {
    font-size: 0.9rem !important;
  }


  .table_head p:nth-child(1) {
    width: 200px !important;
  }

  .sub_title_h4 {
    font-size: 1rem !important;
  }

  .billing_detail_section .btn-warning {
    font-size: 0.8rem !important;
    padding: 10px !important;
  }

  .badge-bg-primary {
    margin-top: 10px !important;
  }
}

/*  ################# SM Screen   ################# */
@media (min-width: 480px) and (max-width: 575px) {

  .signup_image_div h4 {
    font-size: 1.9rem !important;
  }

  /* navbar */

  .header-notification ul {
    flex-wrap: wrap-reverse;
  }

  .header-notification ul .dropdown {
    margin-bottom: 10px;
  }

  .navbar-light .navbar-nav.right-nav .nav-link::after {
    left: 0 !important;
  }

  .desktop {
    flex-direction: row !important;
  }

  .navbar-light .navbar-nav.menu_nav .nav-link {
    padding: 1rem !important;
  }

  .navbar-light .navbar-nav.right-nav.mobile .nav-link {
    font-size: 0.9rem;
    padding: 5px 20px !important;
  }

  .tabs_top_main_address {
    margin-top: 0 !important;
    margin-bottom: 20px;
    border: 0 !important;
    padding: 0 !important;
  }

  .navbar-brand img {
    width: 100px;
  }

  .navbar-brand {
    width: 80%;
  }

  .filterNav {
    justify-content: center !important;
  }

  /* Feature */
  .main-top-section {
    margin-top: 250px !important;
    padding: 10px !important;
  }

  .main-top-section h3 {
    font-size: 1.8rem;
  }

  .footer {
    height: auto !important;
  }

  #page-content-wrapper {
    padding: 20px !important;
  }

  /* pricing  */
  .top_button_section .plan_btn {
    min-width: 180px !important;
    padding: 8px 20px !important;
  }

  .top_button_section .plan_btn.pro {
    margin-left: -30px !important;
  }

  .top_button_section.billing .plan_btn {
    min-width: 160px !important;
    padding: 8px 20px !important;
    font-size: 0.9rem !important;
  }

  .top_button_section.billing .plan_btn.pro {
    margin-left: -30px !important;
  }

  /* home page main bottom sectio */
  .image_div_top::after {
    content: "";
    width: 0 !important;
    height: 0 !important;
  }

  /* property search */
  .react-tabs__tab-panel.search {
    height: auto !important;
  }

  .table-data-main.resp-table2 {
    overflow: scroll !important;
  }

  .message_show {
    width: 90% !important;
    font-size: 0.8rem !important;
    line-height: 1.4 !important;
  }

  .dashboard_footer {
    padding: 10px;
    height: auto !important;
  }

  .search-container {
    height: auto !important;
  }





  /* Affiliation Page */
  .affiliate_card {
    padding: 10px !important;
  }

  /* Affiliation Page */
  .affiliate_card .input-group .form-control {
    margin-bottom: 10px;
    width: 100%;
  }

  .affiliate_card .input-group {
    flex-direction: column;
  }

  .chart_card {
    margin: 10px 0;
    padding: 10px !important;
  }

  .chart_card .chart_card_number {
    padding: 10px !important;
  }


  .affiliate_card h2 {
    font-size: 1.4rem !important;
  }

  .resp-table2 thead th {
    line-height: 1;
    min-width: 100px !important;
    font-size: 0.7rem !important;
  }

  .resp-table2 tbody th {
    font-size: 0.6rem !important;
  }

  .accordion .accordion-item .accordion-button {
    font-size: 0.6rem !important;
    padding: 5px !important;
  }

  .accordion .accordion-item .accordion-body {
    font-size: 0.7rem !important;
  }

  .top_button_section ul {
    width: 100% !important;
  }

  .top_button_section ul li {
    font-weight: 500 !important;
    line-height: 40px !important;
    font-size: 1rem !important;
  }

  .pricing_item .pricing_item_header {
    height: 40px !important;
  }

  /* .pricing_item .pricing_item_price .pricing {
    flex-direction: column !important;
  } */

  .pricing_item .pricing_item_price .pricing .amount {
    font-size: 2rem !important;
    margin: 10px !important;
  }

  .pricing_item .pricing_item_price .pricing .type {
    font-size: 1rem !important;
    margin-top: 0 !important;
  }

  .select_plan {
    width: 100% !important;
    font-size: 0.8rem !important;
    padding: 5px 10px !important;
  }

  .disabled_select_plan {
    width: 100% !important;
    font-size: 0.8rem !important;
    padding: 5px 10px !important;
  }

  .downgrad_plan_btn {
    width: 100% !important;
    font-size: 0.8rem !important;
    padding: 5px 10px !important;
  }

  .pricing_item .pricing_item_price .details h4 {
    font-size: 1rem !important;
  }

  .pricing_item .pricing_item_price .details ul {
    padding: 0 !important;
  }

  .pricing_item .pricing_item_price .details ul li {
    font-size: 0.7rem !important;
    line-height: 1.4 !important;
  }

  .chart-container canvas {
    height: 300px !important;
  }




  .top_button_section ul {
    width: 100% !important;
  }

  .top_button_section ul li {
    font-weight: 500 !important;
    line-height: 40px !important;
    font-size: 1rem !important;
  }

  .pricing_item .pricing_item_header {
    height: 40px !important;
  }

  /* .pricing_item .pricing_item_price .pricing {
    flex-direction: column !important;
  } */

  .pricing_item .pricing_item_price .pricing .amount {
    font-size: 2rem !important;
    margin: 10px !important;
  }

  .pricing_item .pricing_item_price .pricing .type {
    font-size: 1rem !important;
    margin-top: 0 !important;
  }

  .select_plan {
    width: 100% !important;
    font-size: 0.8rem !important;
    padding: 5px 10px !important;
  }

  .disabled_select_plan {
    width: 100% !important;
    font-size: 0.8rem !important;
    padding: 5px 10px !important;
  }

  .downgrad_plan_btn {
    width: 100% !important;
    font-size: 0.8rem !important;
    padding: 5px 10px !important;
  }

  .pricing_item .pricing_item_price .details h4 {
    font-size: 1rem !important;
  }

  .pricing_item .pricing_item_price .details ul {
    padding: 0 !important;
  }

  .pricing_item .pricing_item_price .details ul li {
    font-size: 0.7rem !important;
    line-height: 1.4 !important;
  }

  .table_head p {
    font-size: 0.8rem !important;
  }


  .table_head p:nth-child(1) {
    width: 130px !important;
  }

  .sub_title_h4 {
    font-size: 1rem !important;
  }

  .billing_detail_section .btn-warning {
    font-size: 0.8rem !important;
    padding: 10px !important;
  }

  .signup_image_div h4 {
    font-size: 1.8rem !important;
  }

  /* email */
  .email_verification_page {
    padding: 30px 20px !important;
    height: auto !important;
  }

  .email_verification_page h1 {
    font-size: 2rem !important;
  }

  .email_verification_page p {
    font-size: 0.9rem !important;
  }

  .email_verification_page h3 {
    font-size: 1rem !important;
  }
  .badge-bg-primary {
    margin-top: 10px !important;
  }
}

/* #################  XS Screen ################# */
@media (min-width: 406px) and (max-width: 479px) {
  .register_title {
    padding: 10px 20px !important;
    font-size: 1.4rem !important;
  }

  .register_des {
    padding: 0 20px !important;
  }

  /* .header-notification ul {
  .header-notification ul {
    flex-wrap: wrap-reverse;
  } */

  .header-notification ul .dropdown {
    margin-bottom: 10px;
  }



  #page-content-wrapper {
    padding: 20px !important;
  }

  .filterNav button {
    padding: 6px 8px !important;
    font-size: 0.7rem !important;
    font-weight: 600 !important;
  }

  .filterNav button svg {
    margin-right: 0 !important;
  }

  .remain_downloads {
    padding: 5px !important;
    box-sizing: border-box !important;
    width: auto !important;
  }

  .remain_downloads span:nth-child(1) {
    display: none;
  }

  .remain_downloads:hover span:nth-child(1) {
    display: block;
  }

  .header-notification ul {
    flex-wrap: wrap-reverse;
  }

  /* register page */
  .signup_image_div {
    height: auto !important;
    min-height: 200px !important;
  }

  .signup_image_div h4 {
    font-size: 2rem !important;
  }

  /* navbar */
  .navbar-light .navbar-nav.right-nav .nav-link::after {
    left: 0 !important;
  }

  .desktop {
    flex-direction: row !important;
  }



  .navbar-light .navbar-nav.menu_nav .nav-link {
    padding: 1rem !important;
  }

  .navbar-light .navbar-nav.right-nav.mobile .nav-link {
    font-size: 0.9rem;
    padding: 5px 20px !important;
  }

  .navbar-brand img {
    width: 130px;
  }

  .navbar-brand {
    width: 80%;
  }

  .filterNav {
    justify-content: center !important;
  }

  .filterNav button {
    padding: 6px 8px !important;
    font-size: 0.7rem !important;
    font-weight: 600 !important;
  }

  .filterNav button svg {
    margin-right: 0 !important;
  }

  /* Feature */
  .main-top-section {
    margin-top: 160px !important;
    padding: 10px !important;
  }

  .main-top-section h3 {
    font-size: 1.2rem;
  }

  .footer {
    height: auto !important;
  }

  /* pricing  */
  .top_button_section .plan_btn {
    min-width: 180px !important;
    padding: 8px 20px !important;
  }

  .top_button_section .plan_btn.pro {
    margin-left: -30px !important;
  }

  .top_button_section.billing .plan_btn {
    min-width: 160px !important;
    padding: 8px 20px !important;
    font-size: 0.9rem !important;
  }

  .top_button_section.billing .plan_btn.pro {
    margin-left: -30px !important;
  }

  /* home page main bottom sectio */
  .image_div_top::after {
    content: "";
    width: 0 !important;
    height: 0 !important;
  }

  /* property search */
  .react-tabs__tab-panel.search {
    height: auto !important;
  }

  .table-data-main.resp-table2 {
    overflow: scroll !important;
  }

  .message_show {
    width: 90% !important;
    font-size: 0.8rem !important;
    line-height: 1.4 !important;
  }

  .dashboard_footer {
    flex-direction: column;
    padding: 10px;
    height: auto !important;
  }

  .search-container {
    height: auto !important;
  }

  ul.footerLinks-copy li {
    padding-right: 5px !important;
    margin-right: 0 !important;
    font-size: 0.8rem;
  }

  .signup_image_div {
    height: auto !important;
    min-height: 200px !important;
  }

  .signup_image_div .text-div {
    bottom: 50px !important;
  }


  /* Affiliation Page */
  .affiliate_card {
    padding: 10px !important;
  }

  /* Affiliation Page */
  .affiliate_card .input-group .form-control {
    margin-bottom: 10px;
    width: 100%;
  }

  .affiliate_card .input-group {
    flex-direction: column;
  }

  .chart_card {
    margin: 10px 0;
    padding: 10px !important;
  }

  .chart_card .chart_card_number {
    padding: 10px !important;
  }

  .affiliate_card h2 {
    font-size: 1.4rem !important;
  }

  .resp-table2 thead th {
    line-height: 1;
    min-width: 100px !important;
    font-size: 0.7rem !important;
  }

  .resp-table2 tbody th {
    font-size: 0.6rem !important;
  }

  .accordion .accordion-item .accordion-button {
    font-size: 0.6rem !important;
    padding: 5px !important;
  }

  .accordion .accordion-item .accordion-body {
    font-size: 0.7rem !important;
  }



  .input-group .btn {
    z-index: inherit !important;
  }

  .chart-container canvas {
    height: 300px !important;
  }

  .top_button_section ul {
    width: 100% !important;
  }

  .top_button_section ul li {
    font-weight: 500 !important;
    line-height: 40px !important;
    font-size: 1rem !important;
  }

  .pricing_item .pricing_item_header {
    height: 40px !important;
  }

  /* .pricing_item .pricing_item_price .pricing {
    flex-direction: column !important;
  } */

  .pricing_item .pricing_item_price .pricing .amount {
    font-size: 2rem !important;
    margin: 10px !important;
  }

  .pricing_item .pricing_item_price .pricing .type {
    font-size: 1rem !important;
    margin-top: 0 !important;
  }

  .select_plan {
    width: 100% !important;
    font-size: 0.8rem !important;
    padding: 5px 10px !important;
  }

  .disabled_select_plan {
    width: 100% !important;
    font-size: 0.8rem !important;
    padding: 10px !important;
  }

  .downgrad_plan_btn {
    width: 100% !important;
    font-size: 0.8rem !important;
    padding: 10px !important;

  }

  .pricing_item .pricing_item_price .details h4 {
    font-size: 1rem !important;
  }

  .pricing_item .pricing_item_price .details ul {
    padding: 0 !important;
  }

  .pricing_item .pricing_item_price .details ul li {
    font-size: 0.7rem !important;
    line-height: 1.4 !important;
  }

  /* Billing Details */

  .table_head p {
    font-size: 0.7rem !important;
  }


  .table_head p:nth-child(1) {
    width: 120px !important;
  }

  .sub_title_h4 {
    font-size: 1rem !important;
  }

  .billing_detail_section .btn-warning {
    font-size: 0.8rem !important;
    padding: 10px !important;
  }

  h4.sub_title_h4 {
    font-size: 0.7rem !important;
  }

  .invoice-cst h4.sub_title_h4 {
    font-size: 1rem !important;
  }

  .billing_detail_section_des h4 {
    font-size: 0.9rem !important;
    margin-top: 20px !important;
  }

  .sub_total_section div span {
    font-size: 0.6rem !important;
  }

  .total_section span {
    font-size: 0.6rem;
  }

  .term_checkbox label {
    font-size: 0.6rem !important;
  }

  .billing_detail_section .btn-warning {
    font-size: 0.7rem !important;
    padding: 10px !important;
    margin: 0 auto !important;
    letter-spacing: 0 !important;
  }

  .checkbox_main .img_checkbox_div {
    width: 30px !important;
  }

  .checkbox_main p {
    line-height: 1.2 !important;
  }

  .billing_detail_section {
    padding: 10px !important;
    margin: 10px 0 !important;
  }

  .billing_detail_section_des {
    width: 100% !important;
  }

  /* email */
  .email_verification_page {
    padding: 40px !important;
    min-height: auto !important;
  }

  .email_verification_page h1 {
    font-size: 2rem !important;
  }

  .email_verification_page p {
    font-size: 0.9rem !important;
  }

  .email_verification_page h3 {
    font-size: 1rem !important;
  }

  .mapbox-popup {
    min-width: 200px !important;
  }



  /* invoinces */
  .date_section h5,
  .date_section2 h5,
  .date_section h6 {
    font-size: 0.7rem !important;
  }

  .customer .customer_info {
    width: 100% !important;
  }

  .date_section {
    margin-bottom: 0 !important;
  }

  .date_section h3 {
    font-size: 1rem !important;
    font-weight: 600;
  }

  .deital_head h6 {
    font-size: 0.7rem !important;
  }

  .customer {
    flex-direction: column;
  }

  .customer .image_div {
    margin-bottom: 20px;
  }

  .badge-bg-primary {
    margin-top: 10px !important;
  }
}

@media (min-width: 380px) and (max-width: 405px) {
  .filterNav button {
    font-size: 0.9rem !important;
    padding: 5px 10px !important
  }

  /* email */
  .email_verification_page {
    padding: 30px 20px !important;
    min-height: auto !important;
    justify-content: flex-start !important;
  }


  .email_verification_page h1 {
    font-size: 2rem !important;
  }

  .email_verification_page p {
    font-size: 0.9rem !important;
  }

  .email_verification_page h3 {
    font-size: 1rem !important;
  }

  .signup_image_div {
    height: 500px !important;
  }

  .signup_image_div h4 {
    font-size: 2rem !important;
  }

  .mapbox-popup {
    min-width: 200px !important;
  }
  .header-notification ul {
    flex-wrap: wrap-reverse;
  }

  .dashboard_footer {
    flex-direction: column;
    height: auto !important;
  }

  .dashboard_footer span {
    text-align: center;
  }

  .top_button_section ul {
    width: 100% !important;
  }

  .select_plan {
    width: 100% !important;
    /* font-size: 0.8rem !important; */
    padding: 8px 10px !important;
  }

  .disabled_select_plan {
    width: 100% !important;
  }

  .downgrad_plan_btn {
    width: 100% !important;
  }

  .table_head p {
    font-size: 0.7rem !important;
  }


  .table_head p:nth-child(1) {
    width: 90px !important;
  }

  .sub_title_h4 {
    font-size: 1rem !important;
  }

  .billing_detail_section .btn-warning {
    font-size: 0.8rem !important;
    padding: 10px !important;
  }

  .table_body span {
    font-size: 0.7rem !important;
    line-height: 1.2;
  }

  /* invoinces */
  .date_section h5,
  .date_section2 h5,
  .date_section h6 {
    font-size: 0.7rem !important;
  }

  .customer .customer_info {
    width: 100% !important;
  }

  .date_section {
    margin-bottom: 0 !important;
  }

  .date_section h3 {
    font-size: 0.8rem !important;
  }

  .date_section div {
    flex-direction: column;
  }

  .date_section div h6,
  .date_section div h5 {
    margin: 5px 0 !important;
  }

  .deital_head h6 {
    font-size: 0.7rem !important;
    font-weight: 600;
  }

  .customer {
    flex-direction: column;
  }

  .customer .image_div {
    margin-bottom: 20px;
  }
  .badge-bg-primary {
    margin-top: 10px !important;
  }
}

@media (max-width: 379px) {

  /* navbar */
  .register_title {
    padding: 10px 20px !important;
    font-size: 1.4rem !important;
  }

  .register_des {
    padding: 0 20px !important;
  }

  .tabs ul li a {
    padding: 15px 30px !important;
  }

  .remain_downloads {
    padding: 5px !important;
    box-sizing: border-box !important;
    width: auto !important;
  }

  .remain_downloads span:nth-child(1) {
    display: none;
  }

  .remain_downloads:hover span:nth-child(1) {
    display: block;
  }

  .remain_skiptrace {
    width: auto !important;
    padding: 8px !important;
  }


  .navbar-light .navbar-nav.right-nav .nav-link.linktext {
    padding: 8px 20px !important;
  }

  /* register page */
  .signup_image_div {
    height: auto !important;
    min-height: 200px !important;
  }

  .signup_image_div h4 {
    font-size: 1.8rem !important;
  }

  .navbar-light .navbar-nav.right-nav .nav-link::after {
    left: 0 !important;
  }

  .desktop {
    flex-direction: row !important;
  }

  .navbar-light .navbar-nav.menu_nav .nav-link {
    padding: 1rem !important;
  }

  /* Feature */
  .main-top-section h3 {
    font-size: 1.2rem;
  }

  .main-header-front .banner-content h3 {
    font-size: 2rem !important;
  }

  .main-header-front .banner-content {
    padding: 20px !important;
  }

  .tabs ul li:first-child a {
    margin-bottom: 20px;
  }

  .main-top-section {
    margin-top: 100px !important;
    padding: 10px !important;
  }

  .main-top-section h3 {
    font-size: 1rem !important;
  }

  .bottom-section {
    padding: 0 !important;
  }

  .main-middle-section h4 {
    font-size: 1rem !important;
  }

  .main-middle-section .t-box {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .main-middle-section .t-box .text h6 {
    text-align: center !important;
  }

  .main-middle-section .t-box .text p {
    text-align: center !important;
  }

  .bottom-section .right {
    text-align: center !important;
  }

  .footer {
    height: auto !important;
  }

  /* pricing  */
  .top_button_section .plan_btn {
    min-width: 180px !important;
    padding: 8px 20px !important;
  }

  .top_button_section .plan_btn.pro {
    margin-left: -30px !important;
  }

  .top_button_section.billing .plan_btn {
    min-width: 160px !important;
    padding: 8px 20px !important;
    font-size: 0.9rem !important;
  }

  .top_button_section.billing .plan_btn.pro {
    margin-left: -30px !important;
  }

  /* home page main bottom sectio */
  .image_div_top::after {
    content: "";
    width: 0 !important;
    height: 0 !important;
  }

  /* Billing  */
  .react-tabs__tab-panel h5 {
    font-size: 0.7rem !important;
  }

  .table_head p {
    font-size: 0.7rem;
  }

  .table_body span {
    font-size: 0.7rem !important;
    line-height: 1.2;
  }

  h3.sub_title {
    font-size: 1.2rem !important;
  }

  .term_checkbox label {
    font-size: 0.7rem !important;
    line-height: 1.2;
  }

  .invoice_table h3 {
    font-size: 1.2rem !important;
  }

  .billing_detail_section_des ul li {
    line-height: 1.2;
    font-size: 0.7rem !important;
  }

  /* header */
  .header-notification ul {
    flex-wrap: wrap-reverse;
  }


  .remain_downloads {
    display: none !important;
  }

  .remain_skiptrace {
    display: none !important;
  }

  /* property search */
  .react-tabs__tab-panel.search {
    height: auto !important;
  }

  .react-tabs__tab-list .react-tabs__tab {
    text-align: left !important;
  }

  .searchAddress .tabs_top_main .react-tabs__tab-list .react-tabs__tab {
    width: 100% !important;
  }

  .footer .socialLinks ul {
    width: 100% !important;
  }

  ul.footerLinks-copy li {
    padding-right: 5px !important;
    margin-right: 0 !important;
    font-size: 0.8rem;
  }

  /* Sign Text */
  .signup_image_div .text-div {
    bottom: 50px !important;
  }

  /* message show */
  .message_show {
    width: 90% !important;
    font-size: 0.8rem !important;
    line-height: 1.4 !important;
  }

  .table-data-main.resp-table2 {
    overflow: scroll !important;
  }

  .validation_list li span {
    font-size: 0.5rem !important;
  }

  .dashboard_footer {
    flex-direction: column;
    padding: 10px;
    height: auto !important;
  }

  .dashboard_footer span {
    text-align: center;
  }

  .search-container {
    height: auto !important;
  }

  /* Affiliation Page */
  .affiliate_card .input-group .form-control {
    margin-bottom: 10px;
    width: 100%;
  }

  .affiliate_card .input-group {
    flex-direction: column;
  }

  .chart_card {
    margin: 10px 0;
    padding: 10px !important;
  }

  .chart_card .chart_card_number {
    padding: 10px !important;
  }

  /* Affiliat Programe */
  .sm-column {
    flex-direction: column !important;
  }

  .datePic {
    width: 100% !important;
  }

  .affiliate_card h2 {
    font-size: 1.2rem !important;
  }

  .data-box h2 {
    font-size: 1.1rem !important;
  }

  .sub-data-box .data-div h4 {
    font-size: 1.1rem !important;
  }

  .chart_card .chart_card_number {
    flex-direction: column-reverse;
  }

  .chart-container canvas {
    height: 300px !important;
  }

  .top_button_section ul {
    width: 100% !important;
  }

  .top_button_section ul li {
    font-weight: 500 !important;
    line-height: 40px !important;
    font-size: 1rem !important;
  }

  .pricing_item .pricing_item_header {
    height: 40px !important;
  }

  /* .pricing_item .pricing_item_price .pricing {
    flex-direction: column !important;
  } */

  .pricing_item .pricing_item_price .pricing .amount {
    font-size: 2rem !important;
    margin: 10px !important;
  }

  .pricing_item .pricing_item_price .pricing .type {
    font-size: 1rem !important;
    margin-top: 0 !important;
  }

  .select_plan {
    width: 100% !important;
    font-size: 0.8rem !important;
    padding: 5px 10px !important;
  }

  .disabled_select_plan {
    width: 100% !important;
    font-size: 0.8rem !important;
    padding: 5px 10px !important;
  }

  .downgrad_plan_btn {
    width: 100% !important;
    font-size: 0.8rem !important;
    padding: 5px 10px !important;
  }

  .pricing_item .pricing_item_price .details h4 {
    font-size: 1rem !important;
  }

  .pricing_item .pricing_item_price .details ul {
    padding: 0 !important;
  }

  .pricing_item .pricing_item_price .details ul li {
    font-size: 0.7rem !important;
    line-height: 1.4 !important;
  }


  /* Billing Details */

  .table_head p {
    font-size: 0.5rem !important;
  }


  .table_head p:nth-child(1) {
    width: auto !important;
  }

  .sub_title_h4 {
    font-size: 1rem !important;
  }

  .billing_detail_section .btn-warning {
    font-size: 0.8rem !important;
    padding: 10px !important;
  }

  h4.sub_title_h4 {
    font-size: 0.7rem !important;
  }

  .billing_detail_section_des h4 {
    font-size: 0.9rem !important;
    margin-top: 20px !important;
  }

  .sub_total_section div span {
    font-size: 0.6rem !important;
  }

  .total_section span {
    font-size: 0.6rem;
  }

  .term_checkbox label {
    font-size: 0.6rem !important;
  }

  .billing_detail_section .btn-warning {
    font-size: 0.7rem !important;
    padding: 10px !important;
    margin: 0 auto !important;
    letter-spacing: 0 !important;
  }

  .checkbox_main .img_checkbox_div {
    width: 30px !important;
  }

  .checkbox_main p {
    line-height: 1.2 !important;
  }

  .billing_detail_section {
    padding: 10px !important;
    margin: 10px 0 !important;
  }

  .billing_detail_section_des {
    width: 100% !important;
  }

  /* email */
  .email_verification_page {
    padding: 30px 20px !important;
    height: auto !important;
  }

  .email_verification_page h1 {
    font-size: 2rem !important;
  }

  .email_verification_page p {
    font-size: 0.9rem !important;
  }

  .email_verification_page h3 {
    font-size: 1rem !important;
  } 
  
  .mapbox-popup {
    min-width: 200px !important;
  }
  /* invoinces */
  .date_section h5,
  .date_section2 h5,
  .date_section h6 {
    font-size: 0.6rem !important;
  }

  .customer .customer_info {
    width: 100% !important;
  }

  .date_section {
    margin-bottom: 0 !important;
  }

  .date_section h3 {
    font-size: 0.8rem !important;
  }

  .date_section div {
    flex-direction: column;
  }

  .date_section div h6,
  .date_section div h5 {
    margin: 5px 0 !important;
  }

  .deital_head h6 {
    font-size: 0.6rem !important;
  }


  .customer {
    flex-direction: column;
  }

  .customer .image_div {
    margin-bottom: 20px;
  }
  .badge-bg-primary {
    margin-top: 10px !important;
  }
}

@media (width: 280px) {
  ul.footerLinks-copy li {
    padding-right: 5px !important;
    margin-right: 0 !important;
    font-size: 0.6rem;
  }

  ul.footerLinks-copy li a {
    font-size: 0.6rem;
  }

  .register_section .register_section_overlay .bg_image {
    height: 750px !important;
  }

  .filterNav {
    flex-wrap: wrap;
  }

  .affiliate_card {
    padding: 0 !important;
  }

  .affiliate_card h2 {
    font-size: 1.4rem !important;
  }

  .chart-container canvas {
    height: 200px !important;
  }
}

@media(max-width: 991px) {
  .sidebar ul .logo img.bar-closed {
    display: none;
  }
}

@media(max-width: 767px) {
  body .skips-checkout h2.mb-sm-0 {
    margin-bottom: 0 !important;
  }
  body .header-notification .notify-dropdown {
    top: 130px;
    right:5%;
    width: calc(90%) !important;
  }
  .disabled_select_plan {
    padding: 8px !important;
  }
  .sidebar ul li:hover {
    background-color: transparent !important;
  }
  .sm-m-0 {
    margin: 0 !important;
  }
  .mb-sm-4 {
    margin-bottom: 20px !important;
  }
  .settings_photo_remove {
    float: left;
    margin-left: 28px;
    margin-top: 40px;
  }
  .plan-container .col-xl-4.col-lg-6.col-md-9.col-sm-12.col-12 {
    margin-bottom: 15px !important;
  }
  .containerOfFilter .accordion__panel {
    padding: 20px 10px !important;
  }
  .filter_default li {
    width: 90% !important;
    padding: 4px 0;
    text-align: left;
  }
  .filter_default li svg {
    margin: 4px 5px;
  }
  body .filterNav button {
    width: 90%;
  }
  body .filterNav {
    flex-direction: column;
    padding: 0 !important;
    margin-bottom: 20px;
  }
  .react-tabs__tab-panel.search {
    padding: 0 !important;
    margin: 10px 0;
  }
  .filterArea .col-lg-8.col-md-12.col-sm-12.col-12 {
    padding: 0 !important;
  }
  .mian_filter_class .body-filter {
    background: white;
    padding: 10px 0;
  }
  .setting_form .form-control,
  .setting_form .form-label {
    font-size: 14px !important;
  }
  .glassModel .modal-content svg {
    right:10px !important;
    position: relative !important;
  }
  .affiliate_card {
    padding: 0 !important;
    box-shadow: none !important;
  }
  .affiliate_card .card-body {
    padding: 0 !important;
  }
  .affiliate_card .accordion-button::after {
    width: 1.95rem;
    background-position: right;
  }
  .affiliate_card .accordion-button {
    padding: 5px 15px !important;
  }
  .info_type {
    margin: 10px 0px !important;
  }
  .customer {
    padding: 0 0px !important;
  }
  .customer ul li {
    width: 100%;
    float: left;
  }
  body .cst-pagination {
    min-width: 450px;
    position: relative;
    left: 0;
  }
  body .setting_form h4.title-sub {
    color: var(--subHeadColor);
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
  }

/* New Responsive header */
  body .react-tabs__tab-list .react-tabs__tab:first-child {
    margin-left: 22px !important;
  }
  body .react-tabs__tab-list .react-tabs__tab {
    margin-left: 5px;
    font-size:0.9rem;
  }
  body .react-tabs__tab-list {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .billing_detail_section {
    padding: 30px;
    top: 0 !important;
  }
  body .term_checkbox label {
    font-size: 0.9rem !important;
  }
  body .header-notification .avatar {
    width: 100%;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  body .box-glow {
    text-align: center !important;
  }
  .skips-checkout p {
    margin-bottom: 0;
  }
  body div.skips-checkout .font-lg,
  body h2.bill-gap {
    font-size: 22px !important;
  }
  body .header-notification ul li {
    margin: 8px 0;
    margin-left: 0 !important;
  }
  body .header-notification ul {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  body .main-header {
    padding: 0 10px;
  }
  body .main-title-mobile {
    margin: 0;
  }


  .affiliate-cst h2 {
    font-size: 1.2rem;
    line-height: 1.8;
  }
  #page-content-wrapper .btn-group .btn.active {
    padding-left: 15px;
  }
  
  #page-content-wrapper .btn-group .btn {
    width: 100%;
    text-align: left;
    padding: 10px;
  }

  .affiliate-cst .range-cst label {
    width: 100%;
    text-align: left;
    padding-bottom: 6px;
  }

  body .skips-checkout h3 {
    font-size: 1.2rem;
    line-height: 1.8;
  }
  body .skips-checkout .billing_detail_section h3 {
    text-align: center;
  }
  body .skips-checkout .input-group {
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 0 !important;
  }
  .loaderBlocker {
    z-index: 9999999999;
  }
  .sidebar {
    z-index: 99;
  }
  body .addskip-card .submit_Payment {
    padding: 10px 0 !important;
  }
  body .skips-checkout h4.right {
    text-align: right;
    font-size: 16px;
    margin: 0;
    padding-right: 10px;
    /* font-size: 24px; */
  }
  body .box-glow {
    margin-bottom: 15px;
  }
  body .react-tabs__tab-list .react-tabs__tab:first-child {
    margin-left: 5px !important;
  }
  body .addskip-card span {
    text-align: center !important;
    font-weight: bold;
    margin-bottom: 20px !important;
  }
  body .addskip-card h6,
  body .addskip-card p {
    font-size: 16px !important;
    text-align: center !important;
  }
  body .skips-checkout button.arthmetic {
    width: 50px;
  }
  body .flex-sm {
    display: flex !important;
  }
  body .wd-12 {width: auto !important;}
  
  body .uploadPreview {
    margin-bottom: 20px;
  }
  body .resp-table2.wd-1000 {
    min-width: 1000px;
  }
  body .setting_form.card .card-body {
    padding: 10px 5px;
  }
  body .mob-h2-cst {
    margin-bottom: 20px;
  }
  
  body .mylist-mob-sm {
    margin-bottom: 20px !important;
    font-size: 25px !important;
  }
  .upload-btn-list.sm-wd-100 {
    width: 100% !important;
  }
  body .payment-skin-struct .container-content .badge {
    padding: 5px;
    font-size: 10px;
    border-radius: 2px;
  }
  body .payment-skin-struct .container-content .name,
  body .payment-skin-struct .container-content .number,
  body .payment-skin-struct .container-content .expiry {
    font-size: 15px;
  }
  .datePic.ms-2 {
    margin-left: 0 !important;
  }
  body .header-notification .avatar .text-wrap {
    display: inline !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
      max-width: 550px;
      margin: 1.75rem auto;
  }
}
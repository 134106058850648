.aff_icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aff_icon svg {
  color: #3dcc79;
  font-size: 2rem;
}

.aff_icon_danger {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aff_icon_danger svg {
  color: red;
  font-size: 2rem;
}

.form-label.affiliat {
  text-transform: capitalize !important;
  /* font-weight: 500; */
}

.label_success {
  background-color: #3dcc79;
  padding: 0px 10px;
  border-radius: 8px;
  color: #fff;
}

.gray500-color {
  color: var(--gray500);
  font-weight: 400;
}

.data-box.affiliate {
  padding: 15px !important;
  border-radius: 8px;
  box-shadow: 0px 16px 20px #3348540d;
  min-height: 130px;
  justify-content: flex-start !important;
  height: 100%;
}

.data-box h2 {
  font-weight: 650;
  font-size: 1.4rem;
  color: var(--darkblue);
}

.data-box p {
  font-size: 1.2rem;
  color: var(--darkblue);
  margin-bottom: 0;
  height: 48px;
}

.sub-data-box {
  margin: 5px 0;
}

.sub-data-box .data-div h4 {
  font-size: 1.6rem;
  color: var(--darkblue);
  margin-bottom: 0;
}

.affiliate_card {
  border: none !important;
  border-radius: 8px !important;
  box-shadow: 0px 16px 20px #3348540d;
  padding: 20px;
}

/* .affiliate_card h2 {
  font-weight: 650;
  font-size: 2rem;
  color: var(--darkblue);
} */

.affiliate_card .accordion-button svg {
  font-size: 1.2rem;
}

.accordion .accordion-item .accordion-button .icon_faq {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 5px;
}


@media (max-width: 768px) {
  .affiliate_card h2 {
    font-size: 1.4rem !important;
  }
}

.affiliate_card .accordion-button svg {
  margin-right: 10px;
  font-size: 1.4rem;
  margin-top: -3px;
}

.affiliate_card h3 {
  margin-bottom: 0;
  font-weight: 700;
  color: var(--darkblue);
}

.affiliate_card .btn {
  padding: 12px 30px;
  border-radius: 8px !important;
  position: relative;
  z-index: 0 !important;
}

.affiliate_card .normal {
  border: none !important;
  background-color: var(--lightsky);
  border-radius: 8px 0 0 8px;
}

.affiliate_card .normal2 {
  border: none !important;
  background-color: var(--lightsky);
  border-radius: 8px;
  padding: 10px !important;
}

.affiliat_button {
  margin-top: 30px;
}

.affiliate_card .form-control:disabled,
.affiliate_card .form-control[readonly] {
  background-color: var(--lightsky);
}

.affiliate_card .dateSelet,
.dateSelet {
  border: none !important;
  background-color: #f5f8fa;
  border-radius: 8px 0 0 8px;
  text-align: center;
}

.affiliate_card .search {
  border-radius: 8px !important;
  box-shadow: 0px 4px 4px #0000000a;
}

.affiliate_card .search .form-control {
  background-color: transparent !important;
  border: none;
}

.affiliate_card .search .form-control::placeholder {
  color: var(--darkblue);
  font-size: 0.8rem;
  font-weight: 600;
}

.affiliate_card .search .input-group-text {
  background-color: transparent;
  cursor: pointer;
  border: none;
  color: var(--darkblue);
  font-size: 1.4rem;
}

.customer_profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.customer_profile .img_tr {
  margin-right: 10px;
  width: 40px;
  height: auto;
  border-radius: 100%;
}

.customer_profile .img_tr img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.accordion {
  border: none !important;
  margin-top: 30px;
}

.accordion-header {
  margin: 0 !important;
}

.accordion .accordion-item {
  border: none !important;
  box-shadow: 0px 10px 30px #0000020f;
  margin-bottom: 10px;
}

.accordion .accordion-item .accordion-button:not(.collapsed) {
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: none !important;
  font-weight: 500;
}

.accordion .accordion-item .accordion-button:focus {
  box-shadow: none !important;
}

.accordion .accordion-item .accordion-button {
  background-color: #fff !important;
}

.accordion .accordion-item .accordion-body {
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--gray700) !important;
}

.chart_card {
  padding: 20px;
  margin-bottom: 20px;
}

.chart_card h5 {
  font-weight: 600;
  font-size: 1.4rem;
}

.chart_card .chart_card_number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.label_success_icon {
  color: #3dcc79;
  font-size: 2rem;
}

.label_danger_icon {
  color: #fd554c;
  font-size: 2rem;
}

.label_danger {
  background-color: #fd554c;
  padding: 0px 10px;
  border-radius: 8px;
  color: #fff;
}

.datePic {
  /* width: 100%; */
  background-color: transparent;
  padding: 2px 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--gray300);
  border-radius: 10px;
}

.datePic input:focus {
  background-color: #fff !important;
}
/* .rdt_TableRow,
.mHisf {
  background-color: var(--gray25) !important;
} */
/* .kgOwiN,
.dQpTII {
  padding: 20px 0 !important;
} */
.bord-affiliate {

}
.canvasjs-chart-tooltip {
  box-shadow: #1018281c 1px 1px 10px 1px !important;
  background: white;
  padding: 20px;
}
.canvasjs-chart-tooltip span, 
.canvasjs-chart-tooltip div {
  color: var(--gray700) !important;
  border: 0 !important;
}
.canvasjs-chart-canvas {
  padding: 20px 20px;
  border: 1px solid #EAECF0;
  border-radius: 10px;
}
.kgOwiN:hover,
.dQpTII:hover {
  border-color: var(--gray300) !important;
  border-top: 1px solid var(--gray25) !important;
  cursor: pointer;
  outline-color: var(--gray25) !important;
  outline-style: none !important;
}
body .affiliate-link {}
body .affiliate-link input {
  background: white !important;
  border-radius: 15px;
  padding: 8px 20px;
  color: var(--gray900);    
  transition-duration: 0.15s;
  transition-property: initial !important;
}
body .affiliate-link button.btn-primary {
  background: var(--darkblue) !important;
  border-radius: 25px !important;
  padding: 9px 18px;
}

body .affiliate-link button.btn-primary:hover {
  background-color: var(--gray300) !important;
  color: var(--darkblue) !important;
  cursor: pointer;
  transition: .2s;
}

.setting_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.setting_div .btn-light {
  background-color: transparent !important;
}

.setting_div svg {
  color: #8697A8;
  font-size: 2rem;
}

.datePic span {
  font-size: 1.2rem;
  margin: 0 10px;
}

.datePic svg {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--gray900);
  padding: 5px;
}

.status_label {
  padding: 5px 15px;
}

.status_label.success {
  background-color: rgba(61, 204, 121, 0.1);
  color: #239e56;
}

.status_label.warning {
  background-color: rgba(255, 207, 95, 0.1);
  color: #d4a22c;
}

.status_label_simple.success>div {
  background-color: #ECFDF3 !important;
  color: #027A48 !important;
  padding: 5px 25px;
  border-radius: 100px;
  font-weight: 500 !important;
}

/* .erkbiK div,
.dInjzz div {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: var(--gray900) !important;
}

.iteyOh,
.eSnilV {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: var(--gray900) !important;
}

.faZveH:nth-child(1n) {
  flex-grow: 2 !important;
}

.faZveH:nth-child(2n),
.faZveH:nth-child(3n),
.faZveH:nth-child(4n),
.faZveH:nth-child(5n) {
  justify-content:center !important;
  text-align: center !important;
} */
 
.revenue-cent {
  display: flex;
  margin-left: 10px;
}
.revenue-cent span.digit {
  padding: 8px 15px;
  border-radius: 20px;
  background: #ECFDF3;
  font-size: 14px;
  color: #027A48;
  font-weight: 500;
}
.revenue-cent img {
  height: 33px;
  margin-right: 6px;
}
.status_label_simple.warning>div {
  background-color: rgba(255, 207, 95, 0.1) !important;
  color: #B54708 !important;
  padding: 5px 25px;
  border-radius: 100px;
  font-weight: 500 !important;
}

.dropdown-toggle::after {
  content: none !important;
}

.copy_message {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px 15px;
  font-size: 0.8rem;
  border-radius: 8px !important;
  background-color: rgba(61, 204, 121, 0.1);
  color: #239e56;
}

.copy_message.animated {
  animation: message 1.5s;
}


.affiliate-cst .range-cst .form-control,
.affiliate-cst .range-cst .form-control::placeholder {
  color: var(--gray900) !important;
  font-weight: 500 !important;
}
.affiliate-cst .range-cst label {
  font-weight: 500;
  color: var(--gray700);
}

body p {
  margin: 0 0 15px 0;
  font-weight: 500;
  color: var(--gray500);
}

.affiliate-cst .data-div h1 {
  color: var(--gray800);
  font-weight: 600;
}

.affiliate-cst input.form-control.normal {
  font-weight: 500 !important;
  color: var(--gray900) !important;
}

@keyframes message {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-35px);
  }
}
@import url('./variables.css');

* {
  margin: 0;
  padding: 0;
  font-family: 'Figtree';
}

.layout_section {
  width: 100%;
  height: auto;
  display: flex;
  background-color: var(--bg);
  overflow: hidden;
}

.hover-btn:hover {
  transition:.2s;
  opacity: .7;
  cursor: pointer;
}

button.bg-prim {
}
.color-gold {
  color:#977C46;
  font-weight:600;
}
.main_content_div {
  width: calc(100% - 60px);
  margin-left: 60px;
  min-height: 100vh;
  background-color: var(--bg);
  transition: all 0.2s linear;
}

.main_content_div.active {
  margin-left: 250px !important;
  width: calc(100% - 250px);
}

.side_nav_div {
  height: 100%;
}

.side_nav_div.active .sidebar_main {
  padding: 1rem !important;
}

.sidebar.active ul li a .text {
  padding-left:10px;
}

.sidebar {
  position: fixed;
  width: 60px !important;
  height: 100% !important;
  transition: all 0.2s linear;
  background-color: var(--grayFifty);
  z-index: 900;
  overflow-x: hidden;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  display: none;
}


.sidebar.active {
  width: 250px !important;
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;

}

.sidebar ul {
  position: relative;

}

.sidebar ul li {
  position: relative;
  list-style: none;
  margin-bottom: 15px;
  transition: 0.3s linear;
  padding: 6px 0;
}

.sidebar.active ul li {
  /* padding-left: 10px; */
}

.sidebar ul li.active {
  /* color: var(--gold) !important; */
  /* border-left: 2px solid var(--gold) !important; */
  background: var(--blue1);
  border-left: 0 !important;
  border-radius: 10px;
}

.sidebar ul li:hover {
  /* border-left: 2px solid var(--gold) !important; */
  background-color: var(--blue1);
  border-radius: 10px;
}

.sidebar ul li a .icon svg {
  font-size: 1.6rem;
}



.sidebar ul li:hover a .icon,
.sidebar ul li:hover a .text {
  /* color: var(--gold) !important; */
  /* color: var(--darkblue); */
}

.sidebar ul li.active a .icon,
.sidebar ul li.active a .text {
  /* color: var(--gold) !important; */
  /* color: var(--darkblue); */
}

/* .sidebar ul li.active:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background-color: var(--darkblue);
} */

.sidebar ul .logo {
  padding: 25px 10px 0 10px;
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
  align-items: center;
}

.sidebar.active ul .logo img.navicon {
  height: 28px;
  cursor: pointer;
}
.sidebar.active  ul .logo img.logo-icon {
  width: 65%;
  padding-left: 25px;
  height: auto;
}

.sidebar ul .logo img {
  width: auto;
  height: 40px;
  padding: 0px 6px;
  padding-top: 0px;
  cursor: pointer;
}



.sidebar ul li a {
  position: relative;
  display: flex;
  white-space: nowrap;
  text-decoration: none;
}

.sidebar ul li a .icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  font-size: 1.2em;
  transition: 0.3s linear;
  color: #fff;
  width: 35px !important;
  margin-left: 15px;
}

.sidebar ul li a .text {
  position: relative;
  height: 40px;
  width: 85%;
  color: var(--navFontColor);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1em;
  font-weight: 500;
  padding-left: 20px;
  letter-spacing: 0.05em;
  transition: 0.3s linear;
  text-wrap: wrap;
}

/* header Search */
.status {
  width: 30px;
  height: 30px;
  margin: 0 10px;
}

.status svg {
  font-size: 2rem;
}

.status .blue {
  color: var(--darkblue);
}

.status .gold {
  color: var(--yellow);
}

.status .gray {
  color: var(--text) !important;
}

/* Download & skiptrace */
.number_show {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}



/* Navbar Bottom */
.navbar_bottom_div {
  width: 100%;
  /* height: 220px; */
  left: -2%;
  bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  /* padding: 30px 0; */
}

.navbar_bottom_div .second_div {
  width: 75%;
  margin-top: 10px;
}

.navbar_bottom_div .second_div .powerdby {
  margin-left: 0 !important;
  text-align: left !important;
}

.navbar_bottom_div .second_div .powerdby:hover {
  color: var(--gold);
}

.navbar_bottom_div_design {
  width: 190px;
  height: auto;
  background-image: url('./../new_theme/img/popup-bg.png');
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 10px;
}
.navbar_bottom_div h4 {
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: capitalize;
  color: #fff !important;
  margin-bottom: 0;
}

.navbar_bottom_div span {
  color: #fff;
  font-size: 0.6;
}

.navbar_bottom_div_design h5 {
  z-index: 1;
  font-size: 16px;
  padding: 0 10px;
  font-weight: 700;
  line-height: 1.5;
  color: var(--blue);
  text-align: center;
}

.navbar_bottom_div_design p {
  font-size: 13px;
  line-height: 1.4;
  text-align: center;
  color: var(--gray900);
}
.navbar_bottom_div_design button {
  background: #002854;
  width: auto;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  margin: 0 auto;
  padding: 4px 20px;
  font-size: 14px;
}
.wd-100 {
  width: 100%;
}
.navbar_bottom_div_design svg {
  z-index: 1;
  margin-left: 10px;
  font-size: 1.4rem;
}

.pr-cst-2 {
  padding-right: 5px;
}


/* .navbar_bottom_div_design::after {
  position: absolute;
  bottom: -10px;
  right: -10px;
  content: '';
  width: 120px;
  height: 120px;
  border-radius: 100px;
  z-index: 0;
  background-color: var(--lightsky);
} */

/* SMALL DEVICE */

@media (max-width: 480px) {
  .main_content_div {
    width: calc(100% - 60px) !important;
    margin-left: 60px;
  }

  .main_content_div.active {
    margin-left: 60px !important;
  }


  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
  }


  .navbar_bottom_div {
    display: none;
  }

  /* notification div */
  .header-notification .notify-dropdown {
    /* right: 0 !important; */
  }

  /* .header-notification .notify-dropdown {
    top: 90px !important;
  } */
}

@media (min-width: 480px) and (max-width: 576px) {
  .main_content_div {
    width: calc(100% - 60px) !important;
    margin-left: 60px;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
  }

  /* notification div */
  .header-notification .notify-dropdown {
    /* right: 0 !important; */
  }

  .navbar_bottom_div {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .main_content_div {
    width: calc(100% - 60px) !important;
    margin-left: 60px;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
  }

  /* notification div */
  .header-notification .notify-dropdown {
    /* right: 0 !important; */
  }

  .navbar_bottom_div {
    display: none;
  }

}

@media (min-width: 768px) and (max-width: 853px) {
  .main_content_div {
    width: calc(100% - 60px) !important;
    margin-left: 60px;
  }

  .main_content_div.active {
    margin-left: 60px !important;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
  }

  /* notification div */
  .header-notification .notify-dropdown {
    /* right: 0 !important; */
  }

  .navbar_bottom_div {
    display: none;
  }
}


@media (min-width: 912px) and (max-width: 1024px) {
  .main_content_div {
    width: calc(100% -60px) !important;
    margin-left: 60px;
  }

  /* .sidebar {
    overflow-y: scroll !important;
  } */

  .main_content_div.active {
    width: calc(100% - 250px) !important;
    margin-left: 250px !important;
  }
}




@media (min-width: 1025px) and (max-width: 1999px) {
  .main_content_div {
    width: calc(100% -60px) !important;
    margin-left: 60px;
  }

  .sidebar {
    overflow-y: scroll !important;
  }

  .main_content_div.active {
    width: calc(100% - 250px) !important;
    margin-left: 250px !important;
  }
}


@media (min-width: 1400px) {
  .main_content_div {
    width: calc(100% -60px) !important;
    margin-left: 60px;
  }

  .main_content_div.active {
    width: calc(100% - 250px) !important;
    margin-left: 250px !important;
  }
}
:root {
    --darkblue: #002952 !important;
    --lighblue: #043a80 !important;
    --yellow: #b4975a;
    --lightsky: #EFFAFE;
    --blue: #011C40;
    --gold: #C9922B;

    --bgTheme: white;
    --navFontColor: #1D2939;
    --gray25: #FCFCFD;
    --grayFifty: #F9FAFB;
    --gray100: #F2F4F7;
    --gray200: #EAECF0;
    --gray300: #D0D5DD;
    --gray500: #667085;
    --gray700: #344054;
    --gray800: #1D2939;
    --gray900: #101828;

    --blue1: #E6EAEE;
    --blue6: #002952;
    --blue7: #002346;

    --yellow5050: #FDFBF7;
    --yellow50: #FBF7EF;
    --yellow100: #F2E6CE;
    --yellow600: #C19F59;
    --yellow700: #977C46;
    --yellow900: #977C46;

    --subHeadColor: #303D4D;

    --defFont: 'Figtree';

}
/* Under Mantanence page */
.underMantanence {
    width: 100%;
    height: 100vh;
    min-height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.underMantanence .text_section {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;
}

.underMantanence .overlay {
    width: 100vh;
    height: 100vh;
    min-width: 800px;
    min-height: 800px;
    background-color: rgba(235, 232, 232, 0.2);
    padding: 80px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.underMantanence .overlay .overlay1 {
    background-color: rgba(235, 232, 232, 0.5);
    border-radius: 100%;
    width: 95%;
    height: 95%;
    padding: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.underMantanence .overlay .overlay1 .overlay2 {
    background-color: rgba(235, 232, 232, 0.9);
    border-radius: 100%;
    width: 100%;
    height: 100%;
}


.underMantanence .text_section h3 {
    font-size: 3rem;
    margin: 30px 0;
    color: var(--blue);
    font-weight: 600;
}

.underMantanence .text_section p {
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--blue);
}

@media (max-width: 379px) {
    .underMantanence .text_section h3 {
        font-size: 1.8rem !important;
        text-align: center;
        margin: 20px 0 !important;
    }

    .underMantanence .text_section p {
        font-size: 1.2rem !important;
        text-align: center;
    }


    .underMantanence .overlay {
        width: auto !important;
        height: auto !important;
        min-width: 400px !important;
        min-height: 400px !important;
    }
}

@media (min-width: 380px) and (max-width: 405px) {
    .underMantanence .text_section h3 {
        font-size: 1.8rem !important;
        text-align: center;
        margin: 20px 0 !important;
    }

    .underMantanence .text_section p {
        font-size: 1.2rem !important;
        text-align: center;
    }

    .underMantanence .overlay {
        width: auto !important;
        height: auto !important;
        min-width: 500px !important;
        min-height: 500px !important;
    }
}

@media (min-width: 406px) and (max-width: 479px) {
    .underMantanence .text_section h3 {
        font-size: 1.8rem !important;
        text-align: center;
        margin: 20px 0 !important;
    }

    .underMantanence .text_section p {
        font-size: 1.2rem !important;
        text-align: center;
    }

    .underMantanence .overlay {
        width: auto !important;
        height: auto !important;
        min-width: 500px !important;
        min-height: 500px !important;
    }
}

@media (min-width: 480px) and (max-width: 575px) {
    .underMantanence {
        min-height: 700px !important;
    }

    .underMantanence .text_section h3 {
        font-size: 1.8rem !important;
        text-align: center;
        margin: 20px 0 !important;
    }

    .underMantanence .text_section p {
        font-size: 1.2rem !important;
        text-align: center;
    }

    .underMantanence .overlay {
        width: auto !important;
        height: auto !important;
        min-width: 500px !important;
        min-height: 500px !important;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .underMantanence .text_section h3 {
        font-size: 1.8rem !important;
        text-align: center;
        margin: 20px 0 !important;
    }

    .underMantanence .text_section p {
        font-size: 1.2rem !important;
        text-align: center;
    }

    .underMantanence .overlay {
        width: auto !important;
        height: auto !important;
        min-width: 600px !important;
        min-height: 600px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .underMantanence .text_section h3 {
        font-size: 1.8rem !important;
        text-align: center;
        margin: 20px 0 !important;
    }

    .underMantanence .text_section p {
        font-size: 1.2rem !important;
        text-align: center;
    }

    .underMantanence .overlay {
        width: auto !important;
        height: auto !important;
        min-width: 900px !important;
        min-height: 900px !important;
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    .underMantanence .text_section h3 {
        font-size: 1.8rem !important;
        text-align: center;
        margin: 20px 0 !important;
    }

    .underMantanence .text_section p {
        font-size: 1.2rem !important;
        text-align: center;
    }

    .underMantanence .overlay {
        width: auto !important;
        height: auto !important;
        min-width: 900px !important;
        min-height: 900px !important;
    }
}

@media (min-width: 1024px) and (height: 600px) {
    .underMantanence {
        min-height: 600px !important;
    }

    .underMantanence .text_section h3 {
        font-size: 1.8rem !important;
        text-align: center;
        margin: 20px 0 !important;
    }

    .underMantanence .text_section p {
        font-size: 1.2rem !important;
        text-align: center;
    }

    .underMantanence .overlay {
        width: auto !important;
        height: auto !important;
        min-width: 600px !important;
        min-height: 600px !important;
    }
}
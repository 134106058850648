.about_page_top_section {
    width: 100%;
    min-height: 700px;
    background: url('./../img/Bg2.png') no-repeat;
    background-size: cover;
    background-position: bottom;
    padding-top: 30px;
}

.about_page_top_section .about_us_text {
    padding: 20px;
    min-height: 350px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.about_page_top_section .about_us_text h1 {
    font-size: 3rem;
    font-weight: 700;
    color: var(--blue);
    margin: 10px 0;
}

.about_page_top_section .about_us_text h4 {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--blue);
    margin: 10px 0;
}

.about_page_top_section .about_us_text p {
    font-size: 1.1rem;
    line-height: 1.4;
    font-weight: 500;

}

.subscribe_section {
    width: 100%;
    /* height: 200px; */
    padding: 30px;
    margin: 30px 0;
    background: transparent linear-gradient(76deg, #CCE7FC 0%, #FFF3D9 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    box-shadow: 0px 10px 30px #2C82ED66;
}

.subscribe_section h2 {
    margin-top: 20px;
    font-size: 1.8rem;
    text-align: center;
    font-weight: 700;
    color: var(--blue);
}

.subscribe_section p {
    text-align: center;
    margin-bottom: 0;
    font-weight: 500;
}

.subscribe_section .input-group {
    /* padding: 10px; */
    overflow: hidden;
    background-color: #fff !important;
    border-radius: 30px 20px 20px 30px;
    margin: 20px 0;
}

.subscribe_section .form-control {
    border: 0 !important;
    padding: 15px 20px;
    z-index: 0 !important;
}

.subscribe_section .form-control::placeholder {
    color: var(--blue);
}

.subscribe_section .btn-primary {
    padding: 10px;
    width: 150px;
    z-index: 1;
    border-radius: 20px !important;
}

/* Mission Vision */
.mission_vision {
    width: 100%;
    height: auto;
    padding: 20px;
    margin: 80px 0 40px 0;
}

.mission_vision h1 {
    text-align: center;
    font-size: 3.5rem;
    color: var(--blue);
    font-weight: 700;
}

.mession_vision_text {
    /* margin: 30px 0; */
    padding: 10px;
}

.mession_vision_text h2 {
    font-size: 2rem;
    color: var(--blue);
    font-weight: 700;
    margin: 30px 0;
}

.mession_vision_text p {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5;
}

.flex_div {
    min-height: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.img_div_vision {
    width: 100%;
    min-height: 500px;
    margin-top: 50px;
    padding: 10px;
    border: thin solid #eee;
    border-radius: 20px;
}

.img_div_vision img {
    width: 100%;
    height: 100%;
}

/* why chose us */
.why_choose_us {
    margin-top: 50px;
}

.why_choose_us h1 {
    font-size: 3rem;
    color: var(--blue);
    font-weight: 700;
    text-align: center;
}

.why_choose_us p {
    font-size: 1.2rem;
    line-height: 1.5;
    color: var(--blue);
    font-weight: 500;
    text-align: center;
}

/* Faqs */
.faqs_section {
    margin: 40px 0;
}

.faqs_section .tab-content {
    height: 400px;
    overflow-y: scroll;
}

.faqs_section h1 {
    text-align: center;
    font-weight: 700;
    color: var(--blue);
    font-size: 3.5rem;
}

.faqs_section .nav-tabs {
    border: none !important;
    margin-top: 20px;
}

.faqs_section .nav-tabs .nav-item .nav-link {
    padding: 10px 30px !important;
    color: #000002 !important;
    font-size: 1.2rem;
    margin-left: 5px;
}

.faqs_section .nav-tabs .nav-item .nav-link:hover,
.faqs_section .nav-tabs .nav-item .nav-link:focus-visible {
    border: none !important;
    background-color: var(--lightsky);
    box-shadow: none !important;
    outline: none !important;
}

.faqs_section .nav-tabs .nav-item .nav-link.active {
    background-color: var(--blue) !important;
    border: none !important;
    color: #ffff !important;
    border-radius: 5px !important;
}

.faqs_section .accordion {
    border: none !important;
    margin-top: 30px;
}

.faqs_section .accordion .accordion-item {
    border: none !important;
    box-shadow: 0px 10px 30px #0000020F;
    margin-bottom: 10px;
}

.faqs_section .accordion .accordion-item .accordion-button:not(.collapsed) {
    background-color: #fff !important;
    color: #000 !important;
    box-shadow: none !important;
    font-weight: 500;
}

.faqs_section .accordion .accordion-item .accordion-button:focus {
    box-shadow: none !important;
}

.faqs_section .accordion .accordion-item .accordion-button {
    background-color: #fff !important;
}

.faqs_section .accordion .accordion-item .accordion-body {
    font-size: 0.9rem;
    font-weight: 400;
    color: #7A7A7A !important;
}

/* Media Query  */
@media screen and (max-width:380px) {
    .mission_vision h1 {
        font-size: 2rem;
    }

    .subscribe_section .btn-primary {
        width: 100px;
    }

    .img_div_vision {
        min-height: auto;
    }

    .faqs_section h1 {
        font-size: 2rem;
    }
}

/* Max 480 */
@media screen and (min-width:380px) and (max-width:480px) {
    .mission_vision h1 {
        font-size: 2rem;
    }

    .subscribe_section .btn-primary {
        width: 100px;
    }

    .img_div_vision {
        min-height: auto;
    }

    .faqs_section h1 {
        font-size: 2rem;
    }
}

/* Max 576 */
@media screen and (min-width:480px) and (max-width:576px) {
    .mission_vision h1 {
        font-size: 2rem;
    }

    .subscribe_section .btn-primary {
        width: 100px;
    }

    .img_div_vision {
        min-height: auto;
    }

    .faqs_section h1 {
        font-size: 2rem;
    }
}

/* Max 768 */
@media screen and (min-width:576px) and (max-width:768px) {
    .mission_vision h1 {
        font-size: 2rem;
    }

    .subscribe_section .btn-primary {
        width: 100px;
    }

    .img_div_vision {
        min-height: auto;
    }

    .faqs_section h1 {
        font-size: 2rem;
    }

    .flex_div {
        flex-direction: row;
        min-height: auto !important;
    }
}